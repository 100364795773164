import React from 'react';
import classes from '../Quiz.module.css';
import Choices from './Choices/Choices';
import { Row } from 'reactstrap';
import AnswareInput from './Choices/AnswareInput';

const Question = (props) => {
  const {
    incrementQuestion,
    data,
    quizLength,
    sendAnswares,
    setQuestionAnswares,
    // timeLeft,
  } = props;
  React.useEffect(() => {
    data?.type === 'multiple_choice'
      ? setQuestionAnswares({
          id_question: data?._id,
          type_question: data?.type,
          answer_choices: [],
        })
      : setQuestionAnswares({
          id_question: data?._id,
          type_question: data?.type,
          answer_text: '',
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  return (
    <form className={classes.multisteps_form} id='wizard'>
      <div
        className={classes.multisteps_form_panel}
        style={{ display: 'block' }}
      >
        <div className={classes.question_title}>
          <h1 className='text-center py-5 animate__animated animate__fadeInRight animate_25ms'>
            {data?.content}
          </h1>
        </div>

        <div className={classes.row}>
          <Row style={{ width: '80%' }} className='text-center'>
            {data?.type === 'multiple_choice' ? (
              data?.choices?.map((choice) => {
                return (
                  <Choices
                    key={choice?._id}
                    choice={choice}
                    setQuestionAnswares={setQuestionAnswares}
                  />
                );
              })
            ) : (
              <AnswareInput setQuestionAnswares={setQuestionAnswares} />
            )}
          </Row>
        </div>
      </div>
      <span
        style={{
          position: 'absolute',
          bottom: '10px',
          left: '10px',
          fontStyle: 'italic',
        }}
      >
        *Veuillez sélectionner une ou plusieurs réponses parmi les choix
        proposés.
      </span>
      <button
        type='button'
        className='f_btn next_btn text-uppercase position-absolute'
        id='nextBtn'
        onClick={incrementQuestion}
      >
        {quizLength ? 'Submit' : 'Next Question'}

        <span>
          <i className='fas fa-arrow-right'></i>
        </span>
      </button>
    </form>
  );
};

export default Question;
