import moment from "moment";
import { GET_RESULTS, GET_RESULTS_TECH } from "../Constants/actionsType";

const resultsReducer = (state = [], action) => {
  switch (action.type) {
    case GET_RESULTS:
      return action.payload.filter((result) => {
        result.durationTaken = moment
          .utc(
            moment
              .duration(
                moment(result.submitted_at).diff(moment(result.landed_at)),
              )
              .as("milliseconds"),
          )
          .format("HH:mm:ss");
        result.submitted_at = moment(result.submitted_at).format(
          "DD.MM.YYYY HH:mm",
        );
        return result.testType === "Théorique";
      });
    case GET_RESULTS_TECH:
      return action.payload.filter((result) => {
        result.duration = Math.round(
          moment
            .duration(
              moment(result.submitted_at).diff(moment(result.landed_at)),
            )
            .asHours(),
        );
        result.landed_at = moment(result.landed_at).format("MM/DD/YYYY");
        result.submitted_at = moment(result.submitted_at).fromNow();
        console.log(result.duration);
        return result.testType === "Technique";
      });
    default:
      return state;
  }
};

export default resultsReducer;
