import { Button, Descriptions, Drawer, Typography } from "antd";
import { useState } from "react";
import { CheckCircleTwoTone } from "@ant-design/icons";

const DetailsDrawer = ({ payload }) => {
  console.log(payload);
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Button type="primary" size="small" onClick={showDrawer}>
        Détail
      </Button>
      <Drawer
        title={payload.fullName + " - " + payload.test_name}
        width={720}
        onClose={onClose}
        open={open}
        bodyStyle={{
          paddingBottom: 80,
        }}
      >
        <Descriptions
          title="Généralité"
          className="bg-white p-2 rounded-3"
          column={{
            xxl: 4,
            xl: 4,
            lg: 2,
            md: 1,
            sm: 1,
            xs: 1,
          }}
        >
          <Descriptions.Item label={`Nom et prénom`} span={4} className="pt-2">
            {payload?.fullName}
          </Descriptions.Item>
          <Descriptions.Item label={`Score Total`} span={4} className="pt-2">
            {payload?.global_score} / {payload?.max_score}
          </Descriptions.Item>
          <Descriptions.Item label={`Quiz`} span={4} className="pt-2">
            {payload?.test_name}
          </Descriptions.Item>
        </Descriptions>
        <Descriptions
          title="Quiz"
          className="bg-light p-2 rounded-3"
          column={{
            xxl: 4,
            xl: 4,
            lg: 2,
            md: 1,
            sm: 1,
            xs: 1,
          }}
        >
          {payload.questions.map((elem, index) => {
            return (
              <>
                <Descriptions.Item
                  label={`Question N°${index + 1}`}
                  span={2}
                  className="pt-2"
                >
                  {elem.content}
                </Descriptions.Item>
                <Descriptions.Item label={`Score`} span={1} className="pt-2">
                  {payload?.result?.find(
                    (res) => res?.id_question === elem?._id
                  )?.score
                    ? payload?.result?.find(
                        (res) => res?.id_question === elem?._id
                      )?.score
                    : 0}
                </Descriptions.Item>
                {payload.result?.find(
                  (res) => res?.id_question === elem?._id
                ) ? (
                  <Descriptions.Item label={`Durée`} span={1} className="pt-2">
                    {
                      payload?.result?.find(
                        (res) => res?.id_question === elem?._id
                      )?.answer_duration?.hours
                    }
                    :
                    {
                      payload?.result?.find(
                        (res) => res?.id_question === elem?._id
                      )?.answer_duration?.minutes
                    }
                    :
                    {
                      payload?.result?.find(
                        (res) => res?.id_question === elem?._id
                      )?.answer_duration?.seconds
                    }
                  </Descriptions.Item>
                ) : (
                  <Descriptions.Item label={`Durée`} span={1} className="pt-2">
                    {elem.duration?.hours}:{elem.duration?.minutes}:
                    {elem.duration?.seconds}
                  </Descriptions.Item>
                )}

                {elem.type === "multiple_choice" ? (
                  <Descriptions.Item
                    label={"Reponse(s)"}
                    span={4}
                    className="border-bottom"
                  >
                    <ul>
                      {elem?.choices?.map((element) => {
                        return (
                          <li className="d-flex align-items-center">
                            <Typography.Text
                              mark={payload?.result
                                ?.find((res) => res?.id_question === elem?._id)
                                ?.answer_choices?.includes(element?._id)}
                            >
                              {element?.content}{" "}
                            </Typography.Text>
                            {elem?.correct_choice?.includes(element?.index) && (
                              <CheckCircleTwoTone
                                twoToneColor="#52c41a"
                                color="green"
                                style={{ marginLeft: "5px" }}
                              />
                            )}
                          </li>
                        );
                      })}
                    </ul>
                  </Descriptions.Item>
                ) : (
                  <Descriptions.Item
                    span={4}
                    label={"Reponse(s)"}
                    className="border-bottom"
                  >
                    <Typography.Text mark>
                      {
                        payload?.result?.find(
                          (res) => res?.id_question === elem?._id
                        )?.answer_text
                      }
                    </Typography.Text>
                  </Descriptions.Item>
                )}
              </>
            );
          })}
        </Descriptions>
      </Drawer>
    </>
  );
};
export default DetailsDrawer;
