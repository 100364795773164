import { Button, Descriptions, Drawer, Select, Typography } from "antd";
import { useState } from "react";

const DetailsDrawer = ({ payload }) => {
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Button type="primary" size="small" onClick={showDrawer}>
        Détail
      </Button>
      <Drawer
        title={payload.title}
        width={720}
        onClose={onClose}
        open={open}
        bodyStyle={{
          paddingBottom: 80,
        }}
        // extra={
        //   <Space>
        //     <Button onClick={onClose}>Cancel</Button>
        //     <Button onClick={onClose} type="primary">
        //       Submit
        //     </Button>
        //   </Space>
        // }
      >
        <Descriptions
          title="Genéralité"
          className="border-bottom mb-3"
          column={{
            xxl: 3,
            xl: 3,
            lg: 2,
            md: 1,
            sm: 1,
            xs: 1,
          }}
        >
          <Descriptions.Item label="Description" span={3}>
            <Typography.Paragraph>{payload.description}</Typography.Paragraph>
          </Descriptions.Item>
          <Descriptions.Item label="Niveau">{payload.level}</Descriptions.Item>
          <Descriptions.Item label="Coéfficient">
            {payload.coefficient}
          </Descriptions.Item>
          <Descriptions.Item label="Langue">
            {payload.test_language}
          </Descriptions.Item>
        </Descriptions>
        <Descriptions
          title="Quiz"
          className="bg-light p-2 rounded-3"
          column={{
            xxl: 4,
            xl: 4,
            lg: 2,
            md: 1,
            sm: 1,
            xs: 1,
          }}
        >
          <Descriptions.Item label="Mot clés" span={4}>
            La bonne réponse est mise en surbrillance avec cette marque :{" "}
            <Typography.Text mark>Réponse</Typography.Text>
          </Descriptions.Item>
          {payload.questions.map((elem, index) => {
            return (
              <>
                <Descriptions.Item
                  label={`Question N°${index + 1}`}
                  span={2}
                  className="pt-2"
                >
                  {elem.content}
                </Descriptions.Item>
                <Descriptions.Item label={`Score`} span={1} className="pt-2">
                  {elem.score}
                </Descriptions.Item>
                <Descriptions.Item label={`Durée`} span={1} className="pt-2">
                  {elem.duration.hours}:{elem.duration.minutes}:
                  {elem.duration.seconds}
                </Descriptions.Item>
                {elem.type === "multiple_choice" ? (
                  <Descriptions.Item
                    label={"Reponse(s)"}
                    span={4}
                    className="border-bottom"
                  >
                    <ul>
                      {elem.choices.map((element, index) => {
                        return (
                          <li>
                            <Typography.Text
                              mark={elem.correct_choice.includes(element.index)}
                            >
                              {element.content}
                            </Typography.Text>
                          </li>
                        );
                      })}
                    </ul>
                  </Descriptions.Item>
                ) : (
                  <Descriptions.Item
                    span={4}
                    label={"Reponse(s)"}
                    className="border-bottom"
                  >
                    Libre (Champ de text)
                  </Descriptions.Item>
                )}
              </>
            );
          })}
        </Descriptions>
      </Drawer>
    </>
  );
};
export default DetailsDrawer;
