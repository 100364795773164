import React from "react";
import classes from "../Components/Quiz/Quiz.module.css";
import clock from "../Assets/clock.png";
import "./PracticeTest.css";
const PracticeTimper = (props) => {
  const { hours1, minutes1, seconds1, timeLeft, setTimeLeft } = props;
  // const [timeLeft, setTimeLeft] = React.useState({});

  // Next, set up your countdown effect.
  React.useEffect(() => {
    setTimeLeft({
      hours: parseInt(hours1),
      minutes: parseInt(minutes1),
      seconds: parseInt(seconds1),
    });
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => {
        let updatedTime = { ...prevTime };

        if (updatedTime.seconds > 0) {
          updatedTime.seconds--;
        } else {
          if (updatedTime.minutes > 0) {
            updatedTime.minutes--;
            updatedTime.seconds = 59;
          } else {
            if (updatedTime.hours > 0) {
              updatedTime.hours--;
              updatedTime.minutes = 59;
              updatedTime.seconds = 59;
            }
          }
        }

        return updatedTime;
      });
    }, 1000);
    return () => {
      clearInterval(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // The empty dependency array ensures that the effect runs only once

  // Format the time values with leading zeros
  const formattedHours = String(timeLeft.hours).padStart(2, "0");
  const formattedMinutes = String(timeLeft.minutes).padStart(2, "0");
  const formattedSeconds = String(timeLeft.seconds).padStart(2, "0");
  return (
    <div className="count_box">
      <div className={classes.count_clock} style={{ marginRight: "13px" }}>
        <img src={clock} alt="clock" />
      </div>
      {/* <div className={classes.count_title}>
        <h4 className="ps-1">Test</h4>
        <span className="px-1">Timer</span>
      </div> */}
      <div className={classes.count_number} data-countdown="2022/10/24">
        {formattedHours && (
          <>
            <div className={classes.count_hours}>
              <h3>{formattedHours}</h3>
              <span className="text-uppercase">hrs</span>
            </div>
            <span className={classes.counterSeparator} />
          </>
        )}

        {formattedMinutes && (
          <>
            <div className={classes.count_min}>
              <h3>{formattedMinutes}</h3>
              <span className="text-uppercase">min</span>
            </div>
            <span className={classes.counterSeparator} />
          </>
        )}
        {formattedSeconds && (
          <div className={classes.count_sec}>
            <h3>{formattedSeconds}</h3>
            <span className="text-uppercase">sec</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default PracticeTimper;
