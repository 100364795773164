import React, { useEffect, useState } from "react";
import { Card, Typography, Button, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { submitResult } from "../../Actions/candidat";
import { useNavigate, useParams } from "react-router-dom";

const { Title } = Typography;

function SubmitForm() {
  const Navigate = useNavigate();

  const candidat = useSelector((state) => state.candidat);

  const dispatch = useDispatch();
  const [sendOnce, setSendOnce] = useState(true);

  const { id } = useParams();
  const { id_candidat } = useParams();

  let IDS = JSON.parse(localStorage.getItem("tests_taken")) || [];

  const handleNavigate = () => {
    IDS.push({ _id: id, submitted_at: null });
    localStorage.setItem("tests_taken", JSON.stringify(IDS));
    Navigate("/tests_list");
  };

  useEffect(() => {
    if (sendOnce) {
      dispatch(submitResult(id, id_candidat));
      setSendOnce(false);
    }
  }, [sendOnce, dispatch, id, id_candidat]);

  return candidat.length === 0 ? (
    <div className="vh-100 w-100 bg-light">
      <div className="row row-cols-1 row-cols-sm-2 justify-content-center pt-5 row-cols-md-2 g-3 px-0 mx-0">
        <Card
          title={
            <Title level={3} style={{ textAlign: "center" }}>
              {" "}
              Attendez que votre test soit enregistré
            </Title>
          }
        >
          <div
            style={{
              textAlign: "center",
              marginBottom: "20px",
              padding: "30px 50px",
            }}
          >
            <Spin size="large" />
          </div>
          <Button type="primary" block disabled onClick={handleNavigate}>
            Retour à la page d'accueil
          </Button>
        </Card>
      </div>
    </div>
  ) : (
    <div className="vh-100 w-100 bg-light">
      <div className="row row-cols-1 row-cols-sm-2 justify-content-center pt-5 row-cols-md-2 g-3 px-0 mx-0">
        <Card
          title={
            <Title level={3} style={{ textAlign: "center" }}>
              {" "}
              Votre test a été soumis avec succès !
            </Title>
          }
        >
          <Button type="primary" block onClick={handleNavigate}>
            Retour à la page d'accueil
          </Button>
        </Card>
      </div>
    </div>
  );
}

export default SubmitForm;
