import React, { useEffect, useState } from "react";
import { getTopRanking } from "../../Api";
import { Form, Spin, Select, Typography, Empty } from "antd";
import { useNavigate } from "react-router-dom";

const { Link } = Typography;

const AdminWLCPage = () => {
  const [cat, setCat] = useState(null);
  const [singleCat, setSingleCat] = useState(null);
  const [form] = Form.useForm();
  const [Statistiques, setStats] = useState(null);

  let i = 0;

  const Navigate = useNavigate();

  // useEffect(() => {
  //   async function getTop10Ranking(form, singleCat) {
  //     const { data } = await getTopRanking();
  //     setCat(data.Ranked);
  //     if (singleCat === null) {
  //       form.setFieldsValue({ categorie: data.Ranked[0].Categorie });
  //       setSingleCat(data.Ranked[0]);
  //       setStats(data.Stats);
  //     }
  //     return data;
  //   }
  //   getTop10Ranking(form, singleCat);
  // }, [form, singleCat]);

  return (
    <div className="container mt-3">
      <div className="mb-2">
        <h4>Accueil</h4>
      </div>
      <Empty />
    </div>
  );
};

export default AdminWLCPage;
