import React, { useEffect, useRef, useState } from 'react';
import Highlighter from 'react-highlight-words';
import { Table, Space, Typography, Input, Button } from 'antd';
import { SearchOutlined, DownloadOutlined } from '@ant-design/icons';
import CandidatModal from '../CandidatModal/CandidatModal';
import DropdownActions from '../DropdownActions';
import { CSVLink } from 'react-csv';
import { getCandidats } from '../../../Api';
import { useNavigate } from 'react-router-dom';

const { Text } = Typography;

function CandidatsList() {
  const [candidats, setCandidats] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  useEffect(() => {
    async function fetchData() {
      const { data } = await getCandidats();
      setCandidats(data);
      setLoading(false);
    }
    fetchData();
  }, []);

  const Navigate = useNavigate();

  const ExportedData = candidats.map((candidat) => {
    return {
      nom: candidat.fullName,
      email: candidat.email,
      telephone: candidat.phoneNumber,
      pre_Salaire: candidat.wantedSalary,
      experience: candidat.nbYearExp,
      niveauAnglais: candidat.englishLvl,
      niveauFrancais: candidat.frenchLvl,
      appelEntre: candidat.calledAt,
      Disponibilité: candidat.availablity,
    };
  });

  const headers = [
    { label: 'Nom et Prénom', key: 'nom' },
    { label: 'Email', key: 'email' },
    { label: 'Numéro de Télephone', key: 'telephone' },
    { label: 'Pré-salaire', key: 'pre_Salaire' },
    { label: "Année d'exprience", key: 'experience' },
    { label: 'Niveau en anglais', key: 'niveauAnglais' },
    { label: 'Niveau en français', key: 'niveauFrancais' },
    { label: 'Appelé entre', key: 'appelEntre' },
    { label: 'Disponibilité', key: 'Disponibilité' },
  ];

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size='small'
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type='link'
            size='small'
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: 'Nom et Prénom',
      dataIndex: 'fullName',
      key: 'fullName',
      render: (_, candidat) => (
        <a href={candidat.CV} download>
          {candidat.fullName}
        </a>
      ),
      ...getColumnSearchProps('fullName'),
      fixed: 'left',
    },
    {
      title: "L'école/institut",
      dataIndex: 'Institute',
      ...getColumnSearchProps('Institute'),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (text) => <Text strong>{text}</Text>,
      ...getColumnSearchProps('email'),
    },
    {
      title: 'Télèphone',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      ...getColumnSearchProps('phoneNumber'),
    },
    {
      title: 'Appel Entre',
      dataIndex: 'calledAt',
      key: 'calledAt',
      ...getColumnSearchProps('calledAt'),
    },
    {
      title: 'Expérience',
      dataIndex: 'nbYearExp',
      key: 'nbYearExp',
      ...getColumnSearchProps('nbYearExp'),
      render: (_, candidat) =>
        candidat.nbYearExp === '0' ? (
          <Text>Débutant</Text>
        ) : (
          <Text>{candidat.nbYearExp} Year(s)</Text>
        ),
    },
    {
      title: 'Pré-salariale',
      dataIndex: 'wantedSalary',
      key: 'wantedSalary',
      render: (text) => <Text>{text} DT</Text>,
      ...getColumnSearchProps('wantedSalary'),
    },
    {
      title: 'Actions',
      key: 'action',
      render: (_, candidat) => (
        <Space size='middle'>
          <CandidatModal id={candidat._id} candidat={candidat} />
          <Text
            strong
            className='hoverText text-primary'
            onClick={() => {
              Navigate('/administration/candidat/' + candidat._id);
            }}
          >
            Profil
          </Text>
          <DropdownActions candidat={candidat} />
        </Space>
      ),
    },
  ];

  return (
    <div className=' mt-3'>
      <div className='d-flex justify-content-between mb-4'>
        <div>
          <h4>Liste des candidats</h4>
        </div>
        <CSVLink
          data={ExportedData}
          headers={headers}
          filename='Liste des candidat'
        >
          <Button type='primary' className='d-flex align-items-center'>
            <DownloadOutlined /> Export CSV
          </Button>
        </CSVLink>
      </div>
      <Table
        loading={loading}
        columns={columns}
        dataSource={candidats}
        pagination={{ pageSize: 8 }}
        scroll={{ x: 'max-content' }}
      />
    </div>
  );
}

export default CandidatsList;
