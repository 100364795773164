import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import reducers from "./Reducers";
import { BrowserRouter } from "react-router-dom";
import frFR from "antd/lib/locale/fr_FR";
import "dayjs/locale/fr";
import "./styles.css";
import AppContext from "./Context/AppContext";
import App from "./App";
import { ConfigProvider } from "antd";
import relativeTime from "dayjs/plugin/relativeTime";
import dayjs from "dayjs";

dayjs.extend(relativeTime);
dayjs.locale("fr");

const store = createStore(reducers, compose(applyMiddleware(thunk)));

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <ConfigProvider locale={frFR}>
        <AppContext>
          <App />
        </AppContext>
      </ConfigProvider>
    </Provider>
  </BrowserRouter>
);
