import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { getTest, submitResult } from "../../Api";
import Logo from "../../Assets/logo_neopolis-dev.png";
import {
  Button,
  Form,
  Input,
  Spin,
  Tag,
  Typography,
  message,
  notification,
} from "antd";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import FileBase64 from "react-file-base64";
import ReactStopwatch from "react-stopwatch";
import { EyeOutlined, ClockCircleOutlined } from "@ant-design/icons";

const { Text, Link } = Typography;

const TestTechnique = () => {
  const Navigate = useNavigate();

  let testIDS = JSON.parse(localStorage.getItem("tests_taken"));
  const [test, setTest] = useState({});
  const [block, setBlock] = useState(false);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);

  const { id_test } = useParams();
  const { id_candidat } = useParams();

  const handleNavigateToSubmit = () => {
    Navigate(`/test/${id_test}/submit_from/${id_candidat}`);
  };

  useEffect(() => {}, []);

  const handleDownload = async () => {
    try {
      const { data } = await submitResult(id_test, id_candidat);
      if (data) {
        message.success({
          content: `Bon travail`,
        });
        setBlock(true);
        testIDS.push({ _id: id_test, submitted_at: null });
        localStorage.setItem("tests_taken", JSON.stringify(testIDS));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetTestData = async () => {
    try {
      const { data } = await getTest(id_test);
      setTest(data);
      if (testIDS.some((e) => e._id === id_test)) setBlock(true);
      setLoading(false);
    } catch (error) {
      message.error(error.response.data.message);
      setLoading(false);
    }
  };

  const finishResult = async (formValues) => {
    setLoading(true);
    try {
      const { data } = await axios.patch(
        `https://ws-screening.neopolis-dev.com/result/${id_test}/from/${id_candidat}`,
        formValues
      );
      if (data) {
        setLoading(false);
        message.success("Merci de soumettre votre travail", 4);
        let IDS = JSON.parse(localStorage.getItem("tests_taken"));
        IDS.push({ _id: id_test, submitted_at: Date.now() });
        localStorage.setItem("tests_taken", JSON.stringify(IDS));
        setTimeout(() => {
          Navigate("/");
          window.location.reload();
        }, 4000);
      }
    } catch (error) {}
  };

  useEffect(() => {
    handleGetTestData();
    handleDownload();
  }, []);

  return (
    <div className="min-vh-100 h-100 bg-light">
      {loading ? (
        <div className="d-flex justify-content-center align-items-center pt-5">
          <Spin size="large" />
        </div>
      ) : (
        <div className="container pb-5 pt-3">
          <ReactStopwatch
            seconds={0}
            minutes={0}
            hours={0}
            limit={`04:00:00`}
            onChange={({ hours, minutes, seconds }) => {
              // do something
            }}
            onCallback={() =>
              notification.open({
                type: "warning",
                style: {
                  backgroundColor: "#fff2f0",
                  border: "1px solid #db4654",
                  borderRadius: "13px",
                },
                message: "Délai dépassé. !",
                icon: <ClockCircleOutlined />,
                description:
                  "Vous avez dépassé la limite de temps pour le test.",
                onClick: () => {
                  console.log("Notification Clicked!");
                },
                duration: 0,
              })
            }
            render={({ formatted, hours, minutes, seconds }) => {
              return (
                <Tag
                  color="blue"
                  style={{
                    position: "absolute",
                    top: 10,
                    right: 10,
                    zIndex: "1000",
                    fontSize: "25px",
                    padding: "1rem",
                    borderRadius: "13px",
                  }}
                >
                  {formatted}
                </Tag>
              );
            }}
          />
          <div className="d-flex justify-content-between pb-5 mx-5">
            <div>
              <img src={Logo} alt="..." />
            </div>
            <div>
              <Text>+216 72 246 535</Text>
              <br />
              <Text>info@neopolis-dev.com</Text>
              <br />
              <Text>Tunisie Nabeul : Résidence Baya Cité El Wafa, 8000</Text>
            </div>
          </div>
          <div className="text-center py-4">
            <div className="border-bottom mx-5">
              <h1 className="text-uppercase h4 fw-light">Test technique</h1>
            </div>
          </div>
          <div className="mx-5 border-bottom">
            <h5 className="fw-bold pb-2">{test.title}</h5>
            {/* <h6 className="fw-light pb-2">Description: {test.description}</h6> */}
            <h6 style={{ whiteSpace: "pre-wrap" }}>{test.description}</h6>
            <div style={{ borderRadius: "13px" }} className="p-3 my-3">
              <h6 className="fw-bold pb-2 text-center">
                <Link
                  href={test.image_file}
                  download="Cahier de charge"
                  onClick={handleDownload}
                >
                  Télécharger les fichiers
                </Link>
              </h6>
            </div>
            <h6 className="fw-bold pb-2">
              <Form
                className="bg-white mb-3 rounded-4 shadow-sm p-4"
                form={form}
                disabled={!block}
                layout="vertical"
                onFinish={finishResult}
              >
                <Form.Item
                  name="resultLink"
                  label="Mon Travail"
                  rules={[
                    {
                      required: true,
                      message: "Veuillez votre code",
                    },
                  ]}
                >
                  {test.type_result === "Text/Code" ? (
                    <Input.TextArea
                      autoSize={{
                        minRows: 10,
                        maxRows: 100,
                      }}
                      placeholder="Votre code ici."
                    />
                  ) : test.type_result === "Lien Git" ? (
                    <Input placeholder="Votre Lien ici." />
                  ) : (
                    <FileBase64
                      type="file"
                      multiple={false}
                      onDone={(e) => {
                        form.setFieldsValue({
                          resultLink: e.base64,
                        });
                      }}
                    />
                  )}
                </Form.Item>
                <Button type="primary" htmlType="submit">
                  Soumettre mon travail
                </Button>
              </Form>
            </h6>
          </div>
          <div></div>
        </div>
      )}
    </div>
  );
};

export default TestTechnique;
