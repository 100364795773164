import React, { useEffect, useState } from 'react';
import './PracticeTest.css';
import CodeEditor from './CodeEditor';
import PracticeTimper from './PracticeTimer';
import Typography from 'antd/es/typography/Typography';
import axiosInstance from '../utils/axios';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Spin, notification } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import Swal from 'sweetalert2';

const PracticeTest = () => {
  const [value, setValue] = useState(``);
  const [processing, setProcessing] = useState(false);
  const [surveyTimes, setSurveyTimes] = useState(true);
  const [timeLeft, setTimeLeft] = useState({});
  const [loading, setLoading] = useState(true);
  const [outputDetails, setOutputDetails] = useState(null);
  const [api, contextHolder] = notification.useNotification();
  const [test, setTest] = useState({});
  const { id } = useParams();
  const Navigate = useNavigate();

  // useEffect(() => {
  //   window.addEventListener("visibilitychange", async () => {
  //     if (document.visibilityState === "hidden") {
  //       if (!surveyTimes) {
  //         await handleSubmit(true);
  //       }
  //       if (surveyTimes) {
  //         setSurveyTimes(false);
  //         api["warning"]({
  //           message:
  //             "Attention : Il est strictement interdit de quitter la page du test.",
  //           icon: <EyeOutlined />,
  //           style: {
  //             backgroundColor: "#fff2f0",
  //             border: "1px solid #db4654",
  //             borderRadius: "13px",
  //           },
  //           description:
  //             "Si vous quittez à nouveau la page, votre test sera automatiquement terminé.",
  //           duration: 0,
  //         });
  //       }
  //     }
  //   });
  // }, [surveyTimes]);

  useEffect(() => {
    async function initTest() {
      axiosInstance
        .post('/factory/' + id)
        .then((res) => {
          console.log('Test Saved Successfully');
        })
        .catch((err) => {});
    }
    async function fetchData() {
      axiosInstance
        .get(`/tests/${id}`)
        .then((res) => {
          if (res?.data?.status === 'success') {
            setTest(res?.data?.quiz);
            setLoading(false);
            initTest();
          }
        })
        .catch((err) => {
          Navigate('/tests_list');
          Swal.fire({
            // position: 'top-end',
            icon: 'info',
            title:
              'Désolé, mais vous avez déjà passé ce test !\nVeuillez poursuivre avec les autres tests disponibles.',
            showConfirmButton: false,
            timer: 5000,
            // allowOutsideClick: false,
          });
        });
    }
    fetchData();
  }, []);

  const getOutput = () => {
    let statusId = outputDetails?.status?.id;

    if (statusId === 6) {
      // compilation error
      return (
        <pre className='px-2 py-1 font-normal text-xs text-red-500'>
          {atob(outputDetails?.compile_output)}
        </pre>
      );
    } else if (statusId === 3) {
      return (
        <pre className='px-2 py-1 font-normal text-xs text-green-500'>
          {atob(outputDetails.stdout) !== null
            ? `${atob(outputDetails.stdout)}`
            : null}
        </pre>
      );
    } else if (statusId === 5) {
      return (
        <pre className='px-2 py-1 font-normal text-xs text-red-500'>
          {`Time Limit Exceeded`}
        </pre>
      );
    } else {
      return (
        <pre className='px-2 py-1 font-normal text-xs text-red-500'>
          {atob(outputDetails?.stderr)}
        </pre>
      );
    }
  };

  const handleSubmit = async (_condition) => {
    try {
      const { data } = await axiosInstance.patch(
        '/factory/result/practice/' + id,
        {
          practice_code_result: value,
          practice_duration: {
            hours: String(timeLeft?.hours).padStart(2, '0'),
            minutes: String(timeLeft?.minutes).padStart(2, '0'),
            seconds: String(timeLeft?.seconds).padStart(2, '0'),
          },
        }
      );
      Navigate('/tests_list');
      if (_condition) {
        Swal.fire({
          // position: 'top-end',
          width: '40%',
          icon: 'info',
          title:
            'Attention : Vous avez quitté la page du test une deuxième fois.\nConformément aux règles établies, votre test est maintenant terminé.',

          showConfirmButton: true,
          // timer: 6000,
          allowOutsideClick: false,
        });
      } else {
        Swal.fire({
          // position: 'top-end',
          width: '40%',
          icon: 'success',
          title:
            'Vos réponses ont été enregistrées avec succès !\nVous serez maintenant redirigé(e) vers la page des tests.',
          showConfirmButton: false,
          timer: 6000,
          allowOutsideClick: false,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    document.addEventListener('contextmenu', function (event) {
      event.preventDefault();
    });
  }, []);

  return (
    <div className='vh-100 vw-100 bg-light overflow-hidden'>
      {loading ? (
        <div className='w-100 h-100 d-flex flex-column justify-content-center'>
          <Spin size='large' />
        </div>
      ) : (
        <div className='row h-100'>
          {contextHolder}
          <div className='col-lg-4 bg-dark bg-opacity-25 h-100 d-flex flex-column justify-content-between align-items-center'>
            <div
              className='h-75 w-100'
              style={{
                paddingBottom: '5rem',
                paddingTop: '1rem',
                paddingLeft: '1rem',
              }}
            >
              <div
                className='d-flex justify-content-between align-items-center'
                style={{ marginLeft: '-30px' }}
              >
                <PracticeTimper
                  timeLeft={timeLeft}
                  setTimeLeft={setTimeLeft}
                  hours1={test?.practice_duration?.hours}
                  minutes1={test?.practice_duration?.minutes}
                  seconds1={test?.practice_duration?.seconds}
                />
                <Button
                  size='large'
                  type='primary'
                  onClick={() => handleSubmit(false)}
                >
                  Soumettre
                </Button>
              </div>
              <div className='overflow-y-scroll h-100 pb-5'>
                <Typography.Text strong>{test?.title}</Typography.Text>
                <Typography.Paragraph
                  style={{ whiteSpace: 'pre-wrap', userSelect: 'none' }}
                >
                  {test?.description}
                </Typography.Paragraph>
              </div>
            </div>
            {/* <div
              className='h-25 bg-dark w-100 p-3 rounded-3 mb-1 text-white overflow-y-scroll'
              style={{ marginLeft: '10px' }}
            >
              Output result
              <br />
              {processing ? (
                <Spin size='large' />
              ) : (
                <div>{outputDetails ? <>{getOutput()}</> : null}</div>
              )}
            </div> */}
          </div>
          <CodeEditor
            setOutputDetails={setOutputDetails}
            value={value}
            setValue={setValue}
            setProcessing={setProcessing}
          />
        </div>
      )}
    </div>
  );
};

export default PracticeTest;
