import React, { useEffect, useState } from "react";
import {
  AutoComplete,
  Button,
  Popconfirm,
  Typography,
  notification,
  DatePicker,
  Radio,
  message,
  Tooltip,
} from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import axiosInstance from "../utils/axios";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
dayjs.extend(customParseFormat);
const { RangePicker } = DatePicker;
const range = (start, end) => {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
};

const levels = [
  {
    label: "Débutant",
    value: "Debutant",
  },
  {
    label: "Intermédiaires",
    value: "Intermediaries",
  },
  {
    label: "Avancé",
    value: "Avance",
  },
];

const languages = [
  {
    label: "Français",
    value: "french",
  },
  {
    label: "Anglais",
    value: "english",
  },
];

const SessionForm = () => {
  const [language, setLanguage] = useState("");
  const [level, setLevel] = useState("");
  const [formatedDate, setFormatedDate] = useState([]);
  const [profiles, setProfiles] = useState([]);
  const [tempProfiles, setTempProfiles] = useState([]);
  const [singleSelectedProfile, setSingleSelectedProfile] = useState("");
  const [selectedProfiles, setSelectedProfiles] = useState([]);
  const [tests, setTests] = useState([]);
  const [tempTests, setTempTests] = useState([]);
  const [singleSelectedTest, setSingleSelectedTest] = useState("");
  const [selectedTests, setSelectedTests] = useState([]);
  const [api, contextHolder] = notification.useNotification();

  const openNotificationWithIcon = (type, content, title) => {
    api[type]({
      message: title,
      description: content,
    });
  };

  useEffect(() => {
    async function fetchProfiles() {
      const { data } = await axiosInstance.get("/auth/");
      setProfiles(data.accounts);
      const tempProfs = data.accounts.map((elem) => {
        return { value: elem.email };
      });
      setTempProfiles(tempProfs);
      return data;
    }

    async function fetchTests() {
      const { data } = await axiosInstance.get(
        `/tests/filter/by?language=${language}&level=${level}`
      );
      setTests(data.quizzes);
      return data;
    }

    fetchTests();

    fetchProfiles();
  }, []);

  useEffect(() => {
    async function fetchTests() {
      const { data } = await axiosInstance.get(
        `/tests/filter/by?language=${language}&level=${level}`
      );
      const temp = data?.quizzes?.map((elem) => {
        return { value: elem.title };
      });
      setTempTests(temp);
      return data;
    }

    if (language || level) {
      fetchTests();
    }
  }, [language, level]);

  const onAdd = () => {
    let tempSelectedProfiles = selectedProfiles;

    if (!tempSelectedProfiles.find((elem) => singleSelectedProfile === elem)) {
      tempSelectedProfiles.push(singleSelectedProfile);
      setSelectedProfiles(tempSelectedProfiles);
      setSingleSelectedProfile("");
    } else {
      return openNotificationWithIcon(
        "warning",
        "Le candidat sélectionné déjà existe dans la liste sélectionnée.",
        "Veuillez sélectionner un autre candidat"
      );
    }
  };

  const onAddTest = () => {
    let tempSelectedTests = selectedTests;
    tempSelectedTests.push(singleSelectedTest);
    setSelectedTests(tempSelectedTests);
    setSingleSelectedTest("");
  };

  const handleSave = async () => {
    if (selectedTests.length === 0) {
      return openNotificationWithIcon(
        "error",
        "Veuillez sélectionner au moins un test",
        "Impossible de créer la session!"
      );
    } else if (selectedProfiles.length === 0) {
      return openNotificationWithIcon(
        "error",
        "Veuillez sélectionner un profil de candidat valide",
        "Impossible de créer la session!"
      );
    } else if (formatedDate.length === 0) {
      return openNotificationWithIcon(
        "error",
        "Veuillez sélectionner une durée pour la session",
        "Impossible de créer la session!"
      );
    }

    let users = [];

    selectedProfiles.forEach((elem) => {
      if (profiles.find((element) => element.email === elem)) {
        users.push({
          email: elem,
          status: "Exists",
        });
      } else {
        users.push({
          email: elem,
          status: "Unknown",
        });
      }
    });

    let payload = {
      id_users: users,
      id_tests: tests.filter(
        (elem) => selectedTests.includes(elem.title) && elem._id
      ),
      start_date: formatedDate[0],
      end_date: formatedDate[1],
      status: "Open",
      session_language: language,
    };

    try {
      const { data } = await axiosInstance.post("/session", payload);

      if (data)
        return openNotificationWithIcon(
          "success",
          "",
          "Une nouvelle session a été créée avec succès"
        );
    } catch (error) {
      message.error(error.response.data.message);
    }
  };

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current <= dayjs().subtract(1, "day").endOf("day");
  };

  const deleteSelectedCandidat = (idx) => {
    let temp = [];

    selectedProfiles.forEach((elem, index) => {
      if (idx !== index) {
        temp.push(elem);
      }
    });

    setSelectedProfiles(temp);
  };

  const deleteSelectedTest = (idx) => {
    let temp = [];

    selectedTests.forEach((elem, index) => {
      if (idx !== index) {
        temp.push(elem);
      }
    });

    setSelectedTests(temp);
  };

  return (
    <div className="h-100">
      <h4 className="mb-3">Nouvelle session du test</h4>

      <div className="row">
        <div className="col-lg-6 col-md-12 col-sm-12">
          <Typography.Text>Durée de la session</Typography.Text>

          <RangePicker
            className="mb-4 w-100"
            size="large"
            onChange={(value, dateString) => {
              setFormatedDate(dateString);
            }}
            disabledDate={disabledDate}
            showTime={{
              hideDisabledOptions: true,
              defaultValue: [
                dayjs("00:00:00", "HH:mm:ss"),
                dayjs("11:59:59", "HH:mm:ss"),
              ],
            }}
            format="YYYY-MM-DD HH:mm:ss"
          />
        </div>
        <div className="col-lg-3 col-md-12 col-sm-12">
          <Typography.Text>Langue</Typography.Text>
          <br />
          <Radio.Group
            size="large"
            options={languages}
            onChange={({ target: { value } }) => {
              setLanguage(value);
            }}
            optionType="button"
            buttonStyle="solid"
          />
        </div>
        <div className="col-lg-3 col-md-12 col-sm-12">
          <Typography.Text>Niveau</Typography.Text>
          <br />
          <Radio.Group
            size="large"
            options={levels}
            onChange={({ target: { value } }) => {
              setLevel(value);
            }}
            optionType="button"
            buttonStyle="solid"
          />
        </div>
        <div className="col-lg-6 col-md-12 col-sm-12">
          <Typography.Text>Courriels des candidats</Typography.Text>
          <div className="d-flex">
            <AutoComplete
              size="large"
              options={tempProfiles}
              style={{
                width: "100%",
              }}
              value={singleSelectedProfile}
              onChange={(e) => setSingleSelectedProfile(e)}
              filterOption={(inputValue, option) =>
                option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !==
                -1
              }
            />
            <Button
              size="large"
              type="primary"
              className="d-flex justify-content-center align-items-center"
              style={{ marginLeft: "10px" }}
              onClick={onAdd}
            >
              <PlusOutlined />
            </Button>
          </div>
          <ul className="mx-4 mt-3">
            {selectedProfiles.map((elem, idx) => {
              return (
                <li className="d-flex align-items-center">
                  <Typography.Text
                    type={
                      profiles.find((profile) => profile.email === elem)
                        ? "default"
                        : "danger"
                    }
                  >
                    {elem}
                  </Typography.Text>
                  <Popconfirm
                    title="Êtes-vous sûr de supprimer le candidat sélectionné ?"
                    okText="Oui"
                    onConfirm={() => {
                      deleteSelectedCandidat(idx);
                    }}
                  >
                    {" "}
                    <DeleteOutlined
                      className="mx-2"
                      style={{ cursor: "pointer" }}
                    />
                  </Popconfirm>
                </li>
              );
            })}
          </ul>
        </div>

        <div className="col-lg-6 col-md-12 col-sm-12">
          <Typography.Text>Tests sélectionnés</Typography.Text>
          <div className="d-flex">
            <AutoComplete
              size="large"
              options={tempTests}
              style={{
                width: "100%",
              }}
              placeholder="Choisissez une langue ou un niveau ou les deux"
              value={singleSelectedTest}
              onChange={(e) => setSingleSelectedTest(e)}
              filterOption={(inputValue, option) =>
                option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !==
                -1
              }
            />
            <Button
              size="large"
              type="primary"
              className="d-flex justify-content-center align-items-center"
              style={{ marginLeft: "10px" }}
              onClick={onAddTest}
            >
              <PlusOutlined />
            </Button>
          </div>
          <ul className="mx-4 mt-3">
            {selectedTests.map((elem, idx) => {
              return (
                <li className="d-flex align-items-center">
                  <Typography.Text>
                    {elem} - {tests.find((test) => test?.title === elem)?.level}
                  </Typography.Text>{" "}
                  <Popconfirm
                    title="Êtes-vous sûr de supprimer le test sélectionné ?"
                    okText="Oui"
                    onConfirm={() => {
                      deleteSelectedTest(idx);
                    }}
                  >
                    <DeleteOutlined
                      className="mx-2"
                      style={{ cursor: "pointer" }}
                    />
                  </Popconfirm>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      {contextHolder}

      <Popconfirm
        className="mt-4"
        title="Êtes-vous sûr de sauvegarder la session ?"
        onConfirm={handleSave}
      >
        <Button size="large" type="primary">
          Enregistrer
        </Button>
      </Popconfirm>
    </div>
  );
};

export default SessionForm;
