const LOGIN = "LOGIN";
const FETCH_ALL = "FETCH_ALL";
const LOGIN_ERROR = "LOGIN_ERROR";
const CREATE_TEST = "CREATE_TEST";
const DELETE_TEST = "DELETE_TEST";
const UPDATE_TEST = "UPDATE_TEST";
const CREATE_CANDIDAT = "CREATE_CANDIDAT";
const DELETE_CANDIDAT = "DELETE_CANDIDAT";
const UPDATE_CANDIDAT = "UPDATE_CANDIDAT";
const SUBMIT_RESULT = "SUBMIT_RESULT";
const CREATE_MAIL_CANDIDAT = "CREATE_MAIL_CANDIDAT";
const GET_RESULTS_TECH = "GET_RESULTS_TECH";
const GET_RESULTS = "GET_RESULTS";
const GET_ONE = "GET_ONE";
const UPDATE_SINGLE_CANDIDAT = "UPDATE_SINGLE_CANDIDAT";
export {
  LOGIN,
  FETCH_ALL,
  LOGIN_ERROR,
  CREATE_TEST,
  DELETE_TEST,
  UPDATE_TEST,
  CREATE_CANDIDAT,
  DELETE_CANDIDAT,
  UPDATE_CANDIDAT,
  SUBMIT_RESULT,
  CREATE_MAIL_CANDIDAT,
  GET_RESULTS_TECH,
  GET_RESULTS,
  GET_ONE,
  UPDATE_SINGLE_CANDIDAT,
};
