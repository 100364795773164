import React, { useEffect, useState } from 'react';
import { Space, Table, Typography, Modal } from 'antd';

import './styles.css';
// import TestModal from "../TestModal/TestModal";
import TestModalTech from '../TestModalTech';
import { getTests } from '../../../Api';
import DetailsDrawer from './DetailsDrawer';

const { Text, Link } = Typography;
const { success } = Modal;

function TestList() {
  const [tests, setTests] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      const { data } = await getTests();
      setTests(data.quizzes);

      setLoading(false);
    }
    fetchData();
  }, []);

  const columns = [
    {
      title: 'Titre',
      dataIndex: 'title',
      key: 'title',
      fixed: 'left',
    },
    {
      title: 'Type',
      dataIndex: 'test_type',
      key: 'test_type',
    },
    {
      title: 'Niveau',
      dataIndex: 'level',
      key: 'level',
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, test) => (
        <Space size='middle'>
          <DetailsDrawer payload={test} />
        </Space>
      ),
    },
  ];

  return (
    <div className=''>
      <div className='mb-3'>
        <div>
          <h4>Tests Disponibles</h4>
        </div>
      </div>

      <Table
        loading={loading}
        columns={columns}
        dataSource={tests}
        pagination={{ pageSize: 8 }}
        scroll={{ x: 'max-content' }}
      />
    </div>
  );
}

export default TestList;
