import React, { useEffect, useState } from "react";
import "./Countdown.css";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

function Countdown({ date, disabledAction }) {
  const Navigate = useNavigate();

  const calculateTimeLeft = () => {
    const difference = +new Date(date) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
      if (
        !timeLeft.days &&
        !timeLeft.hours &&
        !timeLeft.minutes &&
        !timeLeft.seconds
      ) {
        if (!disabledAction) {
          Navigate("/tests_list");
          Swal.fire({
            // position: 'top-end',
            icon: "info",
            width: "40%",
            title:
              "Votre session de test a commencé !\nN'oubliez pas que chaque seconde compte !\nBonne chance et faites de votre mieux !",
            showConfirmButton: true,
            // timer: 5000,
            //   allowOutsideClick: false,
          });
          console.log("Countdown ended");
        }
      }
    }, 1000);

    return () => clearTimeout(timer);
  });

  const timerComponents = [];

  ["days", "hours", "minutes", "seconds"].forEach((interval) => {
    timerComponents.push(
      <div key={interval} className="countdown-component">
        <div className="countdown-value">{timeLeft[interval] || 0}</div>
        <div className="countdown-label">{interval}</div>
      </div>
    );
  });

  return (
    <div className="countdown-container">
      {timerComponents.length ? timerComponents : <div>Time's up!</div>}
    </div>
  );
}

export default Countdown;
