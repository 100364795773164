import React, { useEffect, useState } from "react";
import {
  Card,
  Form,
  Input,
  Typography,
  Button,
  Select,
  InputNumber,
  Radio,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { createTest } from "../../../Actions/test";
import { fetchCategories } from "../../../Actions/categorie";
import FileBase64 from "react-file-base64";

import "./styles.css";

const { Title, Text } = Typography;
const { Option } = Select;
const { TextArea } = Input;

function TestForm() {
  const cats = useSelector((state) => state.categories);
  const [resultType, setResultType] = useState("Apple");
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [form1] = Form.useForm();
  const [formTech] = Form.useForm();
  const [created, setCreated] = useState(false);
  const [isTest, setIsTest] = useState(false);
  const [testType, setTestType] = useState("");

  const onChangeResultType = ({ target: { value } }) => {
    setResultType(value);
  };

  useEffect(() => {
    dispatch(fetchCategories());
  }, []);

  const onSubmitFirstPhase = (formValues) => {
    if (formValues.type === "Théorique") {
      setIsTest(true);
      setTestType("Théorique");
    } else {
      setIsTest(true);
      setTestType("Technique");
    }
  };

  const onSubmit = (formValues) => {
    formValues.testType = "Théorique";
    dispatch(createTest(formValues));
    setCreated(true);
    form.resetFields();
  };

  const onSubmitTechnique = (formValues) => {
    formValues.testType = "Technique";
    dispatch(createTest(formValues));
    setCreated(true);
    form.resetFields();
  };
  return (
    <div className="pt-4">
      <Card
        className="mt-5"
        title={
          <Title level={4} style={{ textAlign: "center" }}>
            {!isTest && "Ajouter un nouveau test"}
            {isTest && testType === "Théorique" && "Ajouter un Test Théorique"}
            {isTest && testType === "Technique" && "Ajouter un Test Pratique"}
          </Title>
        }
      >
        {!isTest && (
          <Form
            form={form1}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 16 }}
            layout="horizontal"
            size="middle"
            onFinish={onSubmitFirstPhase}
          >
            <Form.Item
              name="type"
              label="Type de test"
              rules={[{ required: true, message: "Champ obligatoire" }]}
            >
              <Select>
                <Option value="Technique">Pratique</Option>
                <Option value="Théorique">Théorique</Option>
              </Select>
            </Form.Item>

            <Form.Item wrapperCol={{ span: 16, offset: 6 }}>
              <Button type="primary" htmlType="submit">
                Suivant
              </Button>
            </Form.Item>
          </Form>
        )}
        {isTest && testType === "Théorique" && (
          <Form
            form={form}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 16 }}
            layout="horizontal"
            size="middle"
            onFinish={onSubmit}
          >
            <Form.Item name="title" label="Titre" rules={[{ required: true }]}>
              <Input allowClear />
            </Form.Item>
            <Form.Item
              name="description"
              label="Description"
              rules={[{ required: true, message: "Champ obligatoire" }]}
            >
              <TextArea
                placeholder="Description du test..."
                autoSize={{
                  minRows: 3,
                  maxRows: 5,
                }}
              />
            </Form.Item>
            <Form.Item
              name="duration"
              label="Durée (minutes)"
              rules={[{ required: true }]}
            >
              <Input allowClear />
            </Form.Item>
            <Form.Item
              name="categorie"
              label="Catégorie"
              rules={[{ required: true, message: "Champ obligatoire" }]}
            >
              <Select>
                {cats.map((cat) => {
                  return <Option value={cat.categorie}>{cat.categorie}</Option>;
                })}
              </Select>
            </Form.Item>
            <Form.Item
              name="testLink"
              label="Lien du test"
              rules={[{ required: true }]}
            >
              <Input allowClear />
            </Form.Item>

            <Form.Item
              name="image_file"
              label="Image de couvert"
              rules={[{ required: true }]}
            >
              <FileBase64
                type="file"
                multiple={false}
                onDone={(e) => {
                  form.setFieldsValue({
                    image_file: e.base64,
                  });
                }}
              />
            </Form.Item>

            <Form.Item wrapperCol={{ span: 16, offset: 6 }}>
              <Button
                type="primary"
                onClick={() => {
                  setIsTest(false);
                }}
              >
                Retour
              </Button>
              <Button type="primary" htmlType="submit" className="mx-2">
                Ajouter
              </Button>
            </Form.Item>
            <Form.Item
              wrapperCol={{
                lg: { offset: 6 },
                md: { offset: 6 },
                sm: { offset: 6 },
                xs: { offset: 0 },
                span: 16,
              }}
            >
              {created && (
                <Text type="success" Strong>
                  Le test a été créé avec succès
                </Text>
              )}
            </Form.Item>
          </Form>
        )}
        {isTest && testType === "Technique" && (
          <Form
            form={formTech}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 16 }}
            layout="horizontal"
            size="middle"
            onFinish={onSubmitTechnique}
          >
            <Form.Item
              name="title"
              label="Titre"
              rules={[{ required: true, message: "Champ obligatoire" }]}
            >
              <Input allowClear />
            </Form.Item>
            <Form.Item
              name="description"
              label="Description"
              rules={[{ required: true, message: "Champ obligatoire" }]}
            >
              <TextArea
                placeholder="Description du test..."
                autoSize={{
                  minRows: 3,
                  maxRows: 5,
                }}
              />
            </Form.Item>
            <Form.Item
              name="duration"
              label="Durée"
              rules={[{ required: true, message: "Champ obligatoire" }]}
            >
              <InputNumber type={"number"} />
            </Form.Item>
            <Form.Item
              name="categorie"
              label="Catégorie"
              rules={[{ required: true, message: "Champ obligatoire" }]}
            >
              <Select>
                {cats.map((cat) => {
                  return <Option value={cat.categorie}>{cat.categorie}</Option>;
                })}
              </Select>
            </Form.Item>
            <Form.Item
              name="image_file"
              label="Cahier de charge"
              rules={[{ required: true, message: "Champ obligatoire" }]}
            >
              <FileBase64
                type="file"
                multiple={false}
                onDone={(e) => {
                  formTech.setFieldsValue({
                    image_file: e.base64,
                  });
                }}
              />
            </Form.Item>
            <Form.Item
              label="Type de résultat"
              name="type_result"
              rules={[{ required: true, message: "Champ obligatoire" }]}
            >
              <Radio.Group
                options={[
                  {
                    label: "Lien Git",
                    value: "Lien Git",
                  },
                  {
                    label: "Fichier",
                    value: "Fichier",
                  },
                  {
                    label: "Text/Code",
                    value: "Text/Code",
                  },
                ]}
                onChange={onChangeResultType}
                value={resultType}
                optionType="button"
                buttonStyle="solid"
              />
            </Form.Item>
            <Form.Item wrapperCol={{ span: 16, offset: 6 }}>
              <Button
                type="primary"
                onClick={() => {
                  setIsTest(false);
                }}
              >
                Retour
              </Button>
              <Button type="primary" htmlType="submit" className="mx-2">
                Ajouter
              </Button>
            </Form.Item>
          </Form>
        )}
      </Card>
    </div>
  );
}

export default TestForm;
