import { Form, Space, DatePicker, Button, message } from "antd";

import React from "react";

const DatePickerForm = ({ candidat, TriggerFunction }) => {
  const [form] = Form.useForm();

  const handleOk = (formValues) => {
    formValues.statusCand = "FOR_INTERVIEW";
    TriggerFunction(candidat._id, formValues);
    message.success({
      content: "Email d'intervue a étè envoyé avec succes",
      style: {
        marginTop: "50px",
      },
    });
  };

  const onChange = (value, dateString) => {
    form.setFieldsValue({
      interviewDate: dateString,
    });
  };

  return (
    <Form form={form} layout='horizontal' size='middle' onFinish={handleOk}>
      <Form.Item
        name='interviewDate'
        label='Date de l’entrevue'
        rules={[{ required: true, message: "Entrez la date de l’entrevue" }]}
      >
        <Space direction='vertical'>
          <DatePicker showTime format='YYYY-MM-DD HH:mm' onChange={onChange} />
        </Space>
      </Form.Item>
      <Form.Item>
        <Button type='primary' block htmlType='submit'>
          Invité
        </Button>
      </Form.Item>
    </Form>
  );
};

export default DatePickerForm;
