import React, { useEffect, useState } from 'react';
import {
  Button,
  Form,
  Input,
  Spin,
  Card,
  message,
  Typography,
  Select,
} from 'antd';
import Navbar from '../Navbar';
import { useParams, useNavigate } from 'react-router-dom';
import PhoneInput from 'react-phone-number-input';
import { useDispatch, useSelector } from 'react-redux';
import { ChangePassword } from '../../Api';
import { getCandidat, updateSingleCandidat } from '../../Actions/candidat';
import FileBase64 from 'react-file-base64';

const { Text } = Typography;
const { Option } = Select;

const Profile = () => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const [formPassword] = Form.useForm();
  const dispatch = useDispatch();
  const candidat = useSelector((state) => state.candidat);
  const [edit, setEdit] = useState('none');
  const [error, setError] = useState('');
  const Navigate = useNavigate();

  useEffect(() => {
    dispatch(getCandidat(id));
  }, [dispatch, id]);

  const onSubmitEdit = (formValues) => {
    dispatch(updateSingleCandidat(id, formValues));
    message.success({ content: 'Traitement terminé!' });
    setEdit('none');
  };

  const onSubmitPassChange = async (formValues) => {
    if (formValues.password !== formValues.passwordConfirm) {
      setError('Confirmer votre mot de passe !');
      return 0;
    }
    try {
      const { data } = await ChangePassword(id, formValues);
      if (data.status === 'success') {
        message.success({ content: 'Traitement terminé! tu seras déconnecté' });
        setTimeout(() => {
          localStorage.clear();
          Navigate('/login');
        }, 3000);
      }
    } catch (error) {}
  };

  const handleEdit = () => {
    setEdit('edit');
    form.setFieldsValue(candidat);
  };
  return candidat.length === 0 ? (
    <div className='min-vh-100 w-100 bg-light'>
      <Navbar />
      <div
        style={{
          textAlign: 'center',
          margin: '50px 0',
          marginBottom: '20px',
          padding: '30px 50px',
        }}
      >
        <Spin size='large' />
      </div>
    </div>
  ) : (
    <div className='min-vh-100 w-100 bg-light'>
      <Navbar />
      <div className='container d-flex justify-content-center mt-2'>
        <Card
          title={`${candidat.fullName}`}
          className='col-lg-7 col-md-11 col-sm-12'
        >
          {edit === 'none' && (
            <Form
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 16 }}
              layout='horizontal'
              size='middle'
            >
              <Form.Item label='Email'>{candidat.email}</Form.Item>
              <Form.Item label='Numéro de téléphone'>
                {candidat.phoneNumber}
              </Form.Item>
              <Form.Item label='CV'>
                <a href={candidat.CV} download>
                  Télecharger
                </a>
              </Form.Item>
              <Form.Item
                wrapperCol={{
                  lg: { offset: 6 },
                  md: { offset: 6 },
                  sm: { offset: 6 },
                  xs: { offset: 0 },
                  span: 16,
                }}
              >
                <Button onClick={handleEdit}>Modifer</Button>
                <Button
                  className='mx-2'
                  onClick={() => {
                    setEdit('change_pass');
                  }}
                >
                  Changer mon mot de passe
                </Button>
              </Form.Item>
            </Form>
          )}
          {edit === 'edit' && (
            <Form
              form={form}
              labelCol={{ span: 9 }}
              wrapperCol={{ span: 13 }}
              layout='horizontal'
              size='middle'
              onFinish={onSubmitEdit}
            >
              <Form.Item name='fullName' label='Nom et prénom'>
                <Input />
              </Form.Item>
              <Form.Item name='email' label='Email'>
                <Input allowClear />
              </Form.Item>
              <Form.Item name='phoneNumber' label='Numéro de téléphone'>
                <PhoneInput defaultCountry='TN' />
              </Form.Item>
              <Form.Item name='CV' label='CV'>
                <FileBase64
                  type='file'
                  multiple={false}
                  onDone={(e) => {
                    form.setFieldsValue({
                      CV: e.base64,
                    });
                  }}
                />
              </Form.Item>
              <Form.Item
                name='wantedSalary'
                label='Prétention salariale (Net en TND)'
              >
                <Input type={'number'} />
              </Form.Item>
              <Form.Item name='englishLvl' label='Niveau en anglais'>
                <Select defaultValue={form.englishLvl}>
                  <Option value='Débutant (A1)'> Débutant (A1) </Option>
                  <Option value='Connaissances de base (A2)'>
                    {' '}
                    Connaissances de base (A2){' '}
                  </Option>
                  <Option value='Connaissances améliorées (B1)'>
                    {' '}
                    Connaissances améliorées (B1){' '}
                  </Option>
                  <Option value='Autonomie linguistique (B2)'>
                    {' '}
                    Autonomie linguistique (B2){' '}
                  </Option>
                  <Option value='Connaissance expertes (C1)'>
                    {' '}
                    Connaissance expertes (C1){' '}
                  </Option>
                  <Option value='Maitrise (C2)'> Maitrise (C2) </Option>
                </Select>
              </Form.Item>

              <Form.Item name='frenchLvl' label='Niveau en français'>
                <Select defaultValue={form.frenchLvl}>
                  <Option value='Débutant (A1)'> Débutant (A1) </Option>
                  <Option value='Connaissances de base (A2)'>
                    {' '}
                    Connaissances de base (A2){' '}
                  </Option>
                  <Option value='Connaissances améliorées (B1)'>
                    {' '}
                    Connaissances améliorées (B1){' '}
                  </Option>
                  <Option value='Autonomie linguistique (B2)'>
                    {' '}
                    Autonomie linguistique (B2){' '}
                  </Option>
                  <Option value='Connaissance expertes (C1)'>
                    {' '}
                    Connaissance expertes (C1){' '}
                  </Option>
                  <Option value='Maitrise (C2)'> Maitrise (C2) </Option>
                </Select>
              </Form.Item>
              <Form.Item
                wrapperCol={{
                  lg: { offset: 9 },
                  md: { offset: 9 },
                  sm: { offset: 9 },
                  xs: { offset: 0 },
                  span: 16,
                }}
              >
                <Button
                  onClick={() => {
                    setEdit('none');
                  }}
                >
                  Annuler
                </Button>
                <Button htmlType='submit' className='mx-2'>
                  Modifier
                </Button>
              </Form.Item>
            </Form>
          )}
          {edit === 'change_pass' && (
            <Form
              form={formPassword}
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 14 }}
              layout='horizontal'
              size='middle'
              onFinish={onSubmitPassChange}
            >
              <Form.Item label='Nouveau mot de passe' name='password'>
                <Input.Password
                  onChange={() => {
                    setError('');
                  }}
                />
              </Form.Item>
              <Form.Item
                label='Confirmé le mot de passe'
                name='passwordConfirm'
              >
                <Input.Password
                  onChange={() => {
                    setError('');
                  }}
                />
              </Form.Item>
              {error && (
                <Form.Item
                  wrapperCol={{
                    lg: { offset: 8 },
                    md: { offset: 8 },
                    sm: { offset: 8 },
                    xs: { offset: 0 },
                    span: 16,
                  }}
                >
                  <Text type='danger'>{error}</Text>
                </Form.Item>
              )}
              <Form.Item
                wrapperCol={{
                  lg: { offset: 8 },
                  md: { offset: 8 },
                  sm: { offset: 8 },
                  xs: { offset: 0 },
                  span: 16,
                }}
              >
                <Button
                  onClick={() => {
                    setEdit('none');
                  }}
                >
                  Annuler
                </Button>
                <Button htmlType='submit' className='mx-2'>
                  Modifier
                </Button>
              </Form.Item>
            </Form>
          )}
        </Card>
      </div>
    </div>
  );
};

export default Profile;
