import React from "react";
import classes from "../Quiz.module.css";
import clock from "../../../Assets/clock.png";
const Timer = (props) => {
  const {
    hours1,
    minutes1,
    seconds1,
    incrementQuestion,
    currentQuestion,
    timeLeft,
    setTimeLeft,
  } = props;
  // const [timeLeft, setTimeLeft] = React.useState({});

  // Next, set up your countdown effect.
  React.useEffect(() => {
    setTimeLeft({
      hours: parseInt("00"),
      minutes: parseInt("00"),
      seconds: parseInt("60"),
    });
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => {
        let updatedTime = { ...prevTime };

        if (updatedTime.seconds > 0) {
          updatedTime.seconds--;
        } else {
          if (updatedTime.minutes > 0) {
            updatedTime.minutes--;
            updatedTime.seconds = 59;
          } else {
            if (updatedTime.hours > 0) {
              updatedTime.hours--;
              updatedTime.minutes = 59;
              updatedTime.seconds = 59;
            }
          }
        }

        return updatedTime;
      });
    }, 1000);
    return () => {
      clearInterval(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentQuestion]); // The empty dependency array ensures that the effect runs only once

  // Finally, set up your log effect.
  React.useEffect(() => {
    const { hours, minutes, seconds } = timeLeft;

    if (hours === 0 && minutes === 0 && seconds === 0) {
      console.log("Countdown finished!");
      incrementQuestion();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeLeft.seconds]); // This effect runs whenever the time left changes

  // Format the time values with leading zeros
  const formattedHours = String(timeLeft.hours).padStart(2, "0");
  const formattedMinutes = String(timeLeft.minutes).padStart(2, "0");
  const formattedSeconds = String(timeLeft.seconds).padStart(2, "0");
  return (
    <div className={classes.count_box}>
      <div className={classes.count_clock}>
        <img src={clock} alt="clock" />
      </div>
      <div className={classes.count_title}>
        <h4 className="ps-1">Question</h4>
        <span className="px-1">Timer</span>
      </div>
      <div className={classes.count_number} data-countdown="2022/10/24">
        {formattedHours && (
          <>
            <div className={classes.count_hours}>
              <h3>{formattedHours}</h3>
              <span className="text-uppercase">hrs</span>
            </div>
            <span className={classes.counterSeparator} />
          </>
        )}

        {formattedMinutes && (
          <>
            <div className={classes.count_min}>
              <h3>{formattedMinutes}</h3>
              <span className="text-uppercase">min</span>
            </div>
            <span className={classes.counterSeparator} />
          </>
        )}
        {formattedSeconds && (
          <div className={classes.count_sec}>
            <h3>{formattedSeconds}</h3>
            <span className="text-uppercase">sec</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default Timer;
