import React, { useState } from 'react';
import { Form, Input, TimePicker, message, Select, Typography } from 'antd';
import { createCandidat } from '../../Api';
import FileBase64 from 'react-file-base64';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import '../ManualForm/styles.css';
import Navbar from '../Navbar/Navbar';
import mailImg from '../../Assets/Envelope-cuate.svg';
import StepsForm from '../ManualForm/StepsForm';
import { useParams, useNavigate } from 'react-router-dom';

const { Option } = Select;
const { Text } = Typography;

function SharedLink() {
  const [form] = Form.useForm();
  const Navigate = useNavigate();

  // Utils
  const [onSuccessNotif, setOnSuccessNotif] = useState(false);
  const [onNoticeTrue, setOnNoticeTrue] = useState(false);
  const [beginner, setBeginner] = useState(false);

  const { id } = useParams();
  // End of Utils

  // Test Taken Data Stored In localStorage to prevent Data loss on reload
  // const test = JSON.parse(localStorage.getItem("test"));

  const onSubmit = async () => {
    // Making sure the data is not lost when changing Steps
    let candidat = form.getFieldsValue(true);

    let timePicker1 = candidat.callAt[0]._d.toTimeString();
    let timePicker2 = candidat.callAt[1]._d.toTimeString();

    candidat.calledAt = `${timePicker1.slice(0, 5)} - ${timePicker2.slice(
      0,
      5
    )}`;

    try {
      const { data } = await createCandidat(candidat);
      if (data.status === 'success') {
        setOnSuccessNotif(true);

        message.success('Traitement terminé !');
        if (id.length === data._id.length) {
          Navigate(`/test/${id}/to/${data._id}`);
        } else {
          window.open(
            `https://zlokolrzp18.typeform.com/to/${id}#id_candidat=${data._id}&id_test=${id}`
          );
        }
      }
    } catch (error) {
      if (error.response.data.message.includes('E11000 duplicate key')) {
        message.error('Utiliser une autre adresse e-mail');
      }
    }
  };
  const steps = [
    {
      title: 'Généralités',
      content: (
        <>
          <Form.Item
            name='fullName'
            label='Nom et Prénom'
            rules={[
              { required: true, message: 'Veuillez saisir votre nom complet.' },
            ]}
          >
            <Input
              allowClear
              maxLength={32}
              pattern='[a-zA-Z]*'
              onChange={(e) => {
                form.setFieldsValue({
                  fullName: e.target.value.replace(/[^A-Za-z\s]/g, ''),
                });
              }}
            />
          </Form.Item>
          <Form.Item
            name='email'
            label='Email'
            rules={[{ required: true, message: 'Veuillez saisir votre email' }]}
          >
            <Input allowClear />
          </Form.Item>
        </>
      ),
    },
    {
      title: 'Informations pro',
      content: (
        <>
          <Form.Item
            wrapperCol={{
              lg: { offset: 10 },
              md: { offset: 10 },
              sm: { offset: 10 },
              xs: { offset: 0 },
              span: 10,
            }}
            style={{ marginBottom: '0px' }}
          >
            <Text italic mark>
              (Choisir "0", si débutant)
            </Text>
          </Form.Item>
          <Form.Item
            name='nbYearExp'
            label='Années d’expérience (en informatique)'
            rules={[{ required: true, message: 'Veuillez saisir le champ.' }]}
          >
            <Input
              onChange={(e) => {
                if (e.target.value === '0') {
                  setBeginner(true);
                } else {
                  setBeginner(false);
                }
              }}
            />
          </Form.Item>
          {!beginner && (
            <Form.Item
              name='availablity'
              label='Disponibilité'
              rules={[{ required: true, message: 'Veuillez un champ' }]}
            >
              <Select
                onChange={(value) => {
                  if (value === 'immediate') {
                    setOnNoticeTrue(false);
                  } else {
                    setOnNoticeTrue(true);
                  }
                }}
              >
                <Option value='immediate'>Immédiat</Option>
                <Option value='with notice'>Avec préavis</Option>
              </Select>
            </Form.Item>
          )}
          {onNoticeTrue && (
            <Form.Item
              name='ifNotice'
              label='Préavis (mois)'
              rules={[{ required: true, message: 'Veuillez saisir le champ.' }]}
            >
              <Input type={'number'} />
            </Form.Item>
          )}
          <Form.Item
            name='wantedSalary'
            label='Prétention salariale (Net en TND)'
            rules={[{ required: true, message: 'Veuillez saisir le champ' }]}
          >
            <Input type={'number'} />
          </Form.Item>
        </>
      ),
    },

    {
      title: 'Compétences',
      content: (
        <>
          <Form.Item
            name='englishLvl'
            label='Niveau en anglais'
            rules={[{ required: true, message: 'Champ Obligatoire' }]}
          >
            <Select>
              <Option value='Débutant (A1)'> Débutant (A1) </Option>
              <Option value='Connaissances de base (A2)'>
                {' '}
                Connaissances de base (A2){' '}
              </Option>
              <Option value='Connaissances améliorées (B1)'>
                {' '}
                Connaissances améliorées (B1){' '}
              </Option>
              <Option value='Autonomie linguistique (B2)'>
                {' '}
                Autonomie linguistique (B2){' '}
              </Option>
              <Option value='Connaissance expertes (C1)'>
                {' '}
                Connaissance expertes (C1){' '}
              </Option>
              <Option value='Maitrise (C2)'> Maitrise (C2) </Option>
            </Select>
          </Form.Item>

          <Form.Item
            name='frenchLvl'
            label='Niveau en français'
            rules={[{ required: true, message: 'Champ Obligatoire' }]}
          >
            <Select>
              <Option value='Débutant (A1)'> Débutant (A1) </Option>
              <Option value='Connaissances de base (A2)'>
                {' '}
                Connaissances de base (A2){' '}
              </Option>
              <Option value='Connaissances améliorées (B1)'>
                {' '}
                Connaissances améliorées (B1){' '}
              </Option>
              <Option value='Autonomie linguistique (B2)'>
                {' '}
                Autonomie linguistique (B2){' '}
              </Option>
              <Option value='Connaissance expertes (C1)'>
                {' '}
                Connaissance expertes (C1){' '}
              </Option>
              <Option value='Maitrise (C2)'> Maitrise (C2) </Option>
            </Select>
          </Form.Item>
          <Form.Item
            name='CV'
            label='Votre CV sous format (Pdf)'
            rules={[{ required: true, message: 'Champ Obligatoire' }]}
          >
            <FileBase64
              type='file'
              multiple={false}
              onDone={(e) => {
                form.setFieldsValue({
                  CV: e.base64,
                });
              }}
            />
          </Form.Item>
        </>
      ),
    },
    {
      title: 'Contact',
      content: (
        <>
          <Form.Item
            name='phoneNumber'
            label='Numéro de téléphone'
            rules={[
              {
                required: true,
                message: 'Veuillez saisir vote numéro de télphone',
              },
            ]}
          >
            <PhoneInput defaultCountry='TN' />
          </Form.Item>
          <Form.Item
            name='callAt'
            label='Vous préférez être appelé entre'
            rules={[{ required: true, message: 'Champ Obligatoire' }]}
          >
            <TimePicker.RangePicker format='HH:mm' />
          </Form.Item>
        </>
      ),
    },
  ];

  return (
    <div className='min-vh-100 h-100 w-100 bg-light '>
      <Navbar />
      {onSuccessNotif ? (
        <div className='py-5 anim_bg'>
          <div className='container pb-4' style={{ marginTop: '0px' }}>
            <div className='row'>
              <div className='col-lg-6 col-md-6 col-sm-12 mx-auto'>
                <img src={mailImg} alt='...' style={{ width: '90%' }} />
              </div>
              <div className='col-lg-6 col-md-6 col-sm-12 d-flex my-auto flex-column align-items-center justify-content-center'>
                <div>
                  {!JSON.parse(localStorage.getItem('googleData')) && (
                    <h1
                      className='h4 fw-bold mb-2'
                      style={{ letterSpacing: '1px' }}
                    >
                      Votre compte a été bien crée, Nous avons les informations
                      nécessaires à votre sujet. Nous pouvons éventuellement
                      vous contacter via Email ou Appel téléphonique pour
                      vérifier certaines informations.
                    </h1>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className='container'>
          <div className='row py-5'>
            <div className='col-lg-7 col-md-8 col-sm-12 mx-auto'>
              <Form
                form={form}
                labelCol={{ span: 10 }}
                wrapperCol={{ span: 10 }}
                layout='horizontal'
                size='middle'
                onFinish={onSubmit}
              >
                <StepsForm steps={steps} />
              </Form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default SharedLink;
