import React, { useState, useEffect } from 'react';
import { Spin, Typography, Table, Button } from 'antd';
import moment from 'moment';
import { getCandidat } from '../../../Api';
import { CSVLink } from 'react-csv';
import { DownloadOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
const { Text, Link } = Typography;

const CandidatProfile = () => {
  const { id } = useParams();

  console.log();

  const [ExportedData, setExportedData] = useState(null);
  const columns = [
    {
      title: 'Test passé',
      dataIndex: 'testTaken',
      key: 'testTaken',
    },
    {
      title: 'Soumis',
      dataIndex: 'submitted_at',
      key: 'submitted_at',
    },
    {
      title: 'Durée',
      dataIndex: 'durationTaken',
      key: 'durationTaken',
    },
    {
      title: 'Score',
      dataIndex: 'score',
      key: 'score',
      sorter: (a, b) => Number(a.score) - Number(b.score),
      render: (_, result) => (
        <Text>
          {result.score} / {result.scoreGlobal}
        </Text>
      ),
    },
  ];

  const [candidatProfile, setCandidatProfile] = useState(null);

  const handleProfile = async (id) => {
    try {
      const { data } = await getCandidat(id);
      data.testRelated = data.testRelated.filter((result) => {
        result.durationTaken = moment
          .utc(
            moment
              .duration(
                moment(result.submitted_at).diff(moment(result.landed_at))
              )
              .as('milliseconds')
          )
          .format('HH:mm:ss');
        result.submitted_at = moment(result.submitted_at).format(
          'DD.MM.YYYY HH:mm'
        );
        return result;
      });
      setCandidatProfile(data);
      const exportedData = data.testRelated.map((R) => {
        return {
          nom_prenom: data.fullName,
          test_passé: R.testTaken,
          score: R.score,
          scoreGlobal: R.scoreGlobal,
          soumis_a: R.submitted_at,
          durée: R.durationTaken,
        };
      });
      setExportedData(exportedData);
    } catch (error) {
      console.log(error.message);
    }
  };
  const headers = [
    { label: 'Nom et Prénom', key: 'nom_prenom' },
    { label: 'Test passé', key: 'test_passé' },
    { label: 'Score Candidat', key: 'score' },
    { label: 'Score Test', key: 'scoreGlobal' },
    { label: 'Soumis à', key: 'soumis_a' },
    { label: 'Durée', key: 'durée' },
  ];

  useEffect(() => {
    handleProfile(id);
  }, [id]);
  return !candidatProfile ? (
    <div
      style={{
        textAlign: 'center',
        margin: '50px 0',
        marginBottom: '20px',
        padding: '30px 50px',
      }}
    >
      <Spin size='large' />
    </div>
  ) : (
    <div className='pt-5 mt-3'>
      <div className='border-bottom mb-3'>
        <h3>Profil du candidat</h3>
      </div>
      <div className='row'>
        <div className='col-lg-6 col-md-12 col-sm-12 d-flex justify-content-center py-4'>
          <h4
            className='fw-light d-flex flex-column'
            style={{ letterSpacing: '1px' }}
          >
            <Text className='mb-2'>
              <Text strong>Nom et prénom: </Text>
              {candidatProfile.fullName}
            </Text>

            <Text className='mb-2'>
              <Text strong>L'école/institut: </Text>
              {candidatProfile.Institute ? candidatProfile.Institute : 'N/A'}
            </Text>

            <Text className='mb-2'>
              <Text strong>Email: </Text>
              {candidatProfile.email}
            </Text>

            <Text className='mb-2'>
              <Text strong>Numéro du Télèphone: </Text>
              {candidatProfile.phoneNumber}
            </Text>

            <Text className='mb-2'>
              <Text strong>Appeler entre: </Text>
              {candidatProfile.calledAt}
            </Text>

            <Text className='mb-2'>
              <Text strong>Pré-salaire: </Text>
              {candidatProfile.wantedSalary} DT
            </Text>

            <Text className='mb-2'>
              <Text strong>Années d'experience: </Text>
              {candidatProfile.nbYearExp === 0
                ? '0 (Débutant)'
                : candidatProfile.nbYearExp + ' Ans'}
            </Text>

            <Text className='mb-2'>
              <Text strong>Niveau en Anglais: </Text>
              {candidatProfile.englishLvl}
            </Text>

            <Text className='mb-2'>
              <Text strong>Niveau en Français: </Text>
              {candidatProfile.frenchLvl}
            </Text>

            <Text strong style={{ color: '#1e5186' }}>
              Candidat CV:{' '}
              <Link
                href={candidatProfile.CV}
                download={candidatProfile.fullName}
              >
                Télècharger
              </Link>
            </Text>
          </h4>
        </div>
        <div className='col-lg-6 col-md-12 col-sm-12'>
          <CSVLink
            data={ExportedData}
            headers={headers}
            filename={candidatProfile.fullName}
          >
            <Button type='primary' shape='round' icon={<DownloadOutlined />}>
              Export CSV
            </Button>
          </CSVLink>
          <Table
            columns={columns}
            dataSource={candidatProfile.testRelated}
            pagination={{ pageSize: 7 }}
            scroll={{ x: 'max-content' }}
          />
        </div>
      </div>
    </div>
  );
};

export default CandidatProfile;
