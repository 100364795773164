import React, { useState } from 'react';
import {
  Form,
  Input,
  TimePicker,
  message,
  Select,
  Typography,
  Spin,
  notification,
  Tooltip,
} from 'antd';
import { createCandidat } from '../../Api';
import FileBase64 from 'react-file-base64';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import './styles.css';
import Navbar from '../Navbar/Navbar';
import mailImg from '../../Assets/Envelope-cuate.svg';
import StepsForm from './StepsForm';
import { useNavigate } from 'react-router-dom';
const { Option } = Select;
const { Text } = Typography;

function ManualForm() {
  const [api, contextHolder] = notification.useNotification();
  const [form] = Form.useForm();
  const Navigate = useNavigate();

  const [other, setOther] = useState(false);

  const handleNavigate = () => {
    Navigate('/login');
  };

  // Utils
  const [onSuccessNotif, setOnSuccessNotif] = useState(false);
  const [onNoticeTrue, setOnNoticeTrue] = useState(false);
  const [candidatData, setCandidatData] = useState({});
  const [beginner, setBeginner] = useState(false);
  const [loading, setLoading] = useState(false);

  const onSubmit = async () => {
    // Making sure the data is not lost when changing Steps
    setLoading(true);
    let candidat = form.getFieldsValue(true);
    console.log(candidat.callAt);

    let timePicker1 = candidat.callAt[0].$d.toTimeString();
    let timePicker2 = candidat.callAt[1].$d.toTimeString();

    candidat.calledAt = `${timePicker1.slice(0, 5)} - ${timePicker2.slice(
      0,
      5
    )}`;

    if (candidat.Institute === 'Autre (à préciser)') {
      candidat.Institute = candidat.Other;
    }

    if (!candidat.CV) {
      setLoading(false);
      message.error('Veuillez saisir votre CV!');
    }

    try {
      const { data } = await createCandidat(candidat);
      if (data.status === 'success') {
        setLoading(false);
        setOnSuccessNotif(true);
        setCandidatData(data.newCandidat);
        message.success('Compte crée avec succès ! ');
      }
    } catch (error) {
      if (error.response.data.message.includes('E11000 duplicate key')) {
        message.error('Utiliser une autre adresse e-mail');
        setLoading(false);
      }
    }
  };

  const steps = [
    {
      title: 'Généralités',
      content: (
        <>
          <Form.Item
            name='fullName'
            label='Nom et Prénom'
            rules={[
              { required: true, message: 'Veuillez saisir votre nom complet.' },
            ]}
          >
            <Input
              allowClear
              maxLength={32}
              pattern='[a-zA-Z]*'
              onChange={(e) => {
                form.setFieldsValue({
                  fullName: e.target.value.replace(/[^A-Za-z\s]/g, ''),
                });
              }}
            />
          </Form.Item>
          <Form.Item
            name='email'
            label='Email'
            rules={[{ required: true, message: 'Veuillez saisir votre email' }]}
          >
            <Input allowClear />
          </Form.Item>
          <Form.Item
            name='Institute'
            label="L'école/institut"
            rules={[
              {
                required: true,
                message: "Veuillez Choisir  l'école/institut .",
              },
            ]}
          >
            <Select
              onChange={(e) => {
                form.setFieldsValue({ Institute: e });
                if (e === 'Autre (à préciser)') {
                  setOther(true);
                } else {
                  setOther(false);
                }
              }}
            >
              <Option value='Leaders University Nabeul'>
                Leaders University Nabeul
              </Option>
              <Option value='MIT Nabeul'>MIT Nabeul</Option>
              <Option value='ITBS Nabeul'>ITBS Nabeul</Option>
              <Option value='Autre (à préciser)'>Autre (à préciser)</Option>
            </Select>
          </Form.Item>
          {other && (
            <Form.Item
              name='Other'
              label='Autre (à préciser)'
              rules={[
                {
                  required: true,
                  message: "Veuillez Choisir  l'école/institut .",
                },
              ]}
            >
              <Input />
            </Form.Item>
          )}
        </>
      ),
    },
    {
      title: 'Informations pro',
      content: (
        <>
          <Form.Item
            wrapperCol={{
              lg: { offset: 10 },
              md: { offset: 10 },
              sm: { offset: 10 },
              xs: { offset: 0 },
              span: 10,
            }}
            style={{ marginBottom: '0px' }}
          >
            <Text italic mark>
              (Choisir "0", si débutant)
            </Text>
          </Form.Item>
          <Form.Item
            name='nbYearExp'
            label='Années d’expérience (en informatique)'
            rules={[{ required: true, message: 'Veuillez saisir le champ.' }]}
          >
            <Input
              type={'number'}
              onChange={(e) => {
                if (e.target.value === 0) {
                  setBeginner(true);
                } else {
                  setBeginner(false);
                }
              }}
            />
          </Form.Item>
          {!beginner && (
            <Form.Item
              name='availablity'
              label='Disponibilité'
              rules={[{ required: true, message: 'Veuillez un champ' }]}
            >
              <Select
                onChange={(value) => {
                  if (value === 'immediate') {
                    setOnNoticeTrue(false);
                  } else {
                    setOnNoticeTrue(true);
                  }
                }}
              >
                <Option value='immediate'>Immédiat</Option>
                <Option value='with notice'>Avec préavis</Option>
              </Select>
            </Form.Item>
          )}
          {onNoticeTrue && (
            <Form.Item
              name='ifNotice'
              label='Préavis (mois)'
              rules={[{ required: true, message: 'Veuillez saisir le champ.' }]}
            >
              <Input type={'number'} />
            </Form.Item>
          )}
          <Form.Item
            name='wantedSalary'
            label='Prétention salariale (Net en TND)'
            rules={[{ required: true, message: 'Veuillez saisir le champ' }]}
          >
            <Input type={'number'} />
          </Form.Item>
          <Form.Item name='linkedIn' label='Lien LinkedIn'>
            <Input
              onChange={(e) => {
                const str = 'https://www.linkedin.com';
                console.log(!str.search(e.target.value));
                if (!str.search(e.target.value)) {
                  message.error(
                    'Veuillez entrer un lien de profil linkedIn valide!'
                  );
                }
              }}
            />
          </Form.Item>
          <Form.Item name='gitHub' label='Lien GitHub'>
            <Input
              onChange={(e) => {
                const str = 'https://github.com';
                if (!str.search(e.target.value)) {
                  api.info({
                    message: `Warning`,
                    description:
                      'Veuillez entrer un lien de profil github valide!',
                    placement: 'bottomLeft',
                  });
                }
              }}
            />
          </Form.Item>
        </>
      ),
    },

    {
      title: 'Compétences',
      content: (
        <>
          <Form.Item
            name='englishLvl'
            label='Niveau en anglais'
            rules={[{ required: true, message: 'Champ Obligatoire' }]}
          >
            <Select>
              <Option value='Débutant (A1)'> Débutant (A1) </Option>
              <Option value='Connaissances de base (A2)'>
                {' '}
                Connaissances de base (A2){' '}
              </Option>
              <Option value='Connaissances améliorées (B1)'>
                {' '}
                Connaissances améliorées (B1){' '}
              </Option>
              <Option value='Autonomie linguistique (B2)'>
                {' '}
                Autonomie linguistique (B2){' '}
              </Option>
              <Option value='Connaissance expertes (C1)'>
                {' '}
                Connaissance expertes (C1){' '}
              </Option>
              <Option value='Maitrise (C2)'> Maitrise (C2) </Option>
            </Select>
          </Form.Item>

          <Form.Item
            name='frenchLvl'
            label='Niveau en français'
            rules={[{ required: true, message: 'Champ Obligatoire' }]}
          >
            <Select>
              <Option value='Débutant (A1)'> Débutant (A1) </Option>
              <Option value='Connaissances de base (A2)'>
                {' '}
                Connaissances de base (A2){' '}
              </Option>
              <Option value='Connaissances améliorées (B1)'>
                {' '}
                Connaissances améliorées (B1){' '}
              </Option>
              <Option value='Autonomie linguistique (B2)'>
                {' '}
                Autonomie linguistique (B2){' '}
              </Option>
              <Option value='Connaissance expertes (C1)'>
                {' '}
                Connaissance expertes (C1){' '}
              </Option>
              <Option value='Maitrise (C2)'> Maitrise (C2) </Option>
            </Select>
          </Form.Item>

          <Form.Item
            name='CV'
            label='Votre CV sous format (Pdf)'
            rules={[{ required: true, message: 'Champ Obligatoire' }]}
          >
            <FileBase64
              type='file'
              multiple={false}
              onDone={(e) => {
                form.setFieldsValue({
                  CV: e.base64,
                });
              }}
            />
          </Form.Item>
        </>
      ),
    },
    {
      title: 'Contact',
      content: (
        <>
          <Form.Item
            name='phoneNumber'
            label='Numéro de téléphone'
            rules={[
              {
                required: true,
                message: 'Veuillez saisir vote numéro de télphone',
              },
            ]}
          >
            <PhoneInput defaultCountry='TN' />
          </Form.Item>
          <Form.Item
            name='callAt'
            label='Vous préférez être appelé entre'
            rules={[{ required: true, message: 'Champ Obligatoire' }]}
          >
            <TimePicker.RangePicker format='HH:mm' />
          </Form.Item>
        </>
      ),
    },
  ];

  return (
    <div className='min-vh-100 h-100% w-100 bg-light '>
      <Navbar />
      {onSuccessNotif ? (
        <div className='py-5 anim_bg'>
          <div className='container pb-4' style={{ marginTop: '0px' }}>
            <div className='row'>
              <div className='col-lg-6 col-md-6 col-sm-12 mx-auto'>
                <img src={mailImg} alt='...' style={{ width: '90%' }} />
              </div>
              <div className='col-lg-6 col-md-6 col-sm-12 d-flex my-auto flex-column align-items-center justify-content-center'>
                <div>
                  {contextHolder}
                  <h1
                    className='h4 fw-bold mb-2'
                    style={{ letterSpacing: '1px' }}
                  >
                    Votre compte a été bien crée,
                    <br />
                    Votre mot de passe a été envoyé par email sur l'adresse "
                    {candidatData.email}"<br />
                    Utilisez votre adresse email et votre mot de passe pour se
                    connecter <br />
                    <button className='cta mt-2' onClick={handleNavigate}>
                      <span>Se connecter</span>
                      <svg viewBox='0 0 13 10' height='10px' width='15px'>
                        <path d='M1,5 L11,5'></path>
                        <polyline points='8 1 12 5 8 9'></polyline>
                      </svg>
                    </button>
                    <br />
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className='container'>
          <div className='row py-5'>
            <div className='col-lg-7 col-md-8 col-sm-12 mx-auto'>
              {loading && (
                <div className='d-flex justify-content-center mb-3'>
                  <Spin size='large' />
                </div>
              )}
              <Form
                form={form}
                labelCol={{ span: 10 }}
                wrapperCol={{ span: 10 }}
                layout='horizontal'
                size='middle'
                onFinish={onSubmit}
              >
                <StepsForm steps={steps} />
              </Form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ManualForm;
