const catReducer = (state = [], action) => {
  switch (action.type) {
    case "CREATE_CAT":
      return [...state, action.payload];
    case "FETCH_CATS":
      return action.payload;
    case "UPDATE_CAT":
      return state.map((cat) => {
        return cat._id === action.payload._id ? action.payload : cat;
      });
    case "DELETE_CAT":
      return state.filter((cat) => cat._id !== action.payload);
    default:
      return state;
  }
};

export default catReducer;
