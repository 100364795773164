import { useState } from "react";
import GlobalContext from "./GlobalContext";

const AppContext = (props) => {
  const [duration, setDuration] = useState(
    localStorage.getItem("duration") ? localStorage.getItem("duration") : ""
  );
  return (
    <GlobalContext.Provider value={{ duration, setDuration }}>
      {props.children}
    </GlobalContext.Provider>
  );
};

export default AppContext;
