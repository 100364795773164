import React, { useState, useRef } from "react";
import { Form, Button, Input, message } from "antd";
import { createAdmin } from "../../../Api";
import { useReactToPrint } from "react-to-print";
import AdminPDF from "../AdminPDF";
const AdminForm = () => {
  const componentRef = useRef();
  const [form] = Form.useForm();
  const [autoPassword, setAutoPassword] = useState(false);
  const [ableToDownload, setAbleToDownload] = useState(false);
  const [downloadData, setDownloadData] = useState(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleAutoPassword = () => {
    setAutoPassword(!autoPassword);
  };

  const OnFinish = async (formValues) => {
    if (autoPassword) {
      formValues.autoPassword = true;
    }
    try {
      const { data } = await createAdmin(formValues);
      if (data) {
        message.success("Compte a étè créer avec success!");
        setAbleToDownload(true);
        setDownloadData(data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="container ">
      <div className="mb-3">
        <h4>Nouvelle Admin</h4>
      </div>
      <div className="row flex-column-reverse flex-lg-row">
        <div className="col-lg-7 col-md-12 col-sm-12 mt-2 ">
          <Form
            form={form}
            labelCol={{
              xs: { span: 24 },
              sm: { span: 12 },
              md: { span: 8 },
              lg: { span: 8 },
            }}
            wrapperCol={{
              xs: { span: 24 },
              sm: { span: 12 },
              md: { span: 12 },
              lg: { span: 12 },
            }}
            layout="horizontal"
            size="middle"
            onFinish={OnFinish}
          >
            <Form.Item
              name="fullName"
              label="Nom et Prénom"
              rules={[{ required: true, message: "Champ Obligatoire!" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="email"
              label="Email"
              rules={[{ required: true, message: "Champ Obligatoire!" }]}
            >
              <Input type="email" />
            </Form.Item>
            {!autoPassword && (
              <>
                <Form.Item
                  name="password"
                  label="Mot de passe"
                  rules={[{ required: true, message: "Champ Obligatoire!" }]}
                >
                  <Input.Password />
                </Form.Item>
                <Form.Item
                  name="passwordConfirm"
                  label="Confirmé mot de passe"
                  rules={[{ required: true, message: "Champ Obligatoire!" }]}
                >
                  <Input.Password />
                </Form.Item>
              </>
            )}
            <Form.Item
              wrapperCol={{
                xs: { span: 24 },
                sm: { span: 12, offset: 12 },
                md: { span: 12, offset: 8 },
                lg: { span: 12, offset: 8 },
              }}
            >
              <div className="d-flex justify-content-between">
                {!ableToDownload ? (
                  <>
                    <Button type="primary" htmlType="submit">
                      Créer compte!
                    </Button>
                    <Button onClick={handleAutoPassword}>
                      {!autoPassword
                        ? "Générer un mot de passe"
                        : "Mot de passe manuel"}
                    </Button>
                  </>
                ) : (
                  <>
                    <Button onClick={handlePrint}>Télècharge Pdf</Button>
                  </>
                )}
              </div>
            </Form.Item>
          </Form>
        </div>
        <div className="col-lg-5 col-md-12 col-sm-12">
          <h5 className="fw-light" style={{ letterSpacing: "1px" }}>
            Le compte admin est similaire au super admin, il ne peut seulement
            pas créer de compte admin, mais le reste est identique.
            <br /> <br /> <span className="text-info">Recommandé</span> :
            Générer un mot de passe en utilisant le bouton{" "}
            <span className="fw-bold" style={{ color: "#1e5186" }}>
              'Générer un mot de passe'
            </span>{" "}
            pour un mot de passe plus sécurisé.
            <br /> <br /> Une fois le compte créé, vous pouvez télécharger un
            fichier pdf pour plus de détails sur le compte.
          </h5>
        </div>
      </div>
      {downloadData && (
        <div style={{ display: "none" }}>
          <div ref={componentRef}>
            <AdminPDF data={downloadData} />
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminForm;
