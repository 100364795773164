import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "antd";
import Logo from "../../Assets/logo_neopolis-dev.png";
import { useState } from "react";
import { useEffect } from "react";

function Navbar() {
  const [token, setToken] = useState(localStorage.getItem("token"));
  const _id = localStorage.getItem("_id");
  const Navigate = useNavigate();

  useEffect(() => {
    setToken(localStorage.getItem("token"));
  }, []);

  const Register = () => {
    Navigate("/getting-started/");
  };

  const ReturnHome = () => {
    Navigate("/");
  };

  const NavigateProfile = () => {
    Navigate(`/profile/${_id}`);
  };

  const NavigateTestsList = () => {
    Navigate("/tests_list");
  };

  const handleLogout = () => {
    localStorage.clear();
    Navigate("/login");
  };
  return (
    <div>
      <nav
        className="navbar navbar-expand-sm navbar-dark bg-dark"
        aria-label="Third navbar example"
      >
        <div className="container-fluid">
          <span className="navbar-brand" onClick={ReturnHome}>
            <img src={Logo} style={{ height: "35px" }} alt="Logo" />
          </span>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarsExample03"
            aria-controls="navbarsExample03"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div
            className="collapse navbar-collapse justify-content-between"
            id="navbarsExample03"
          >
            <ul className="navbar-nav mr-auto">
              <li className="nav-item" style={{ cursor: "pointer" }}>
                <span className="nav-link" onClick={ReturnHome}>
                  Accueil
                </span>
              </li>
              <li className="nav-item" style={{ cursor: "pointer" }}>
                <a
                  className="nav-link"
                  href="https://www.neopolis-dev.com/index.php/contact/"
                >
                  Contact
                </a>
              </li>
              {!token && (
                <li className="nav-item" style={{ cursor: "pointer" }}>
                  <span className="nav-link" onClick={Register}>
                    S'inscrire
                  </span>
                </li>
              )}
              {token && (
                <li className="nav-item" style={{ cursor: "pointer" }}>
                  <span className="nav-link" onClick={NavigateProfile}>
                    {" "}
                    Profil
                  </span>
                </li>
              )}
              {token && (
                <li className="nav-item" style={{ cursor: "pointer" }}>
                  <span className="nav-link" onClick={NavigateTestsList}>
                    {" "}
                    Tests disponibles
                  </span>
                </li>
              )}
            </ul>
            <div className="my-2 my-md-0">
              <Button type="primary" onClick={handleLogout}>
                {token ? "Se deconnecter" : "Se connecter"}
              </Button>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
