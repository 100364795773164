import {
  Button,
  Form,
  Input,
  Radio,
  Spin,
  TimePicker,
  Typography,
  message,
} from 'antd';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import axiosInstance from '../utils/axios';
import UploadInput from '../Dashboard/Components/UploadInput';

const PracticeTestFromBeta = ({ form }) => {
  const [loader, setLoader] = useState(false);
  const handleSave = async (formValues) => {
    setLoader(true);
    const duration = {
      hours: dayjs(formValues.practice_duration).format('HH'),
      minutes: dayjs(formValues.practice_duration).format('mm'),
      seconds: dayjs(formValues.practice_duration).format('ss'),
    };

    const data = {
      ...formValues,
      practice_duration: duration,
      test_type: 'Practice',
    };

    try {
      const res = await axiosInstance.post('/tests', data);
      message.success('Enregistrer avec succès');
      form.resetFields();
      setLoader(false);
    } catch (error) {
      console.log(error);
      message.error(error?.response?.data?.message);
      setLoader(false);
    }
  };
  return (
    <div style={{ width: '100%' }}>
      <div className='mb-3'>
        <Typography.Text style={{ fontSize: '18px' }} strong>
          Nouvelle Test Pratique
        </Typography.Text>
      </div>
      <Form
        form={form}
        disabled={loader}
        onFinish={handleSave}
        className='row'
        layout='vertical'
      >
        <div className='col-lg-5'>
          <Form.Item
            label='Titre'
            name='title'
            rules={[{ required: true, message: 'Veuillez fournir un titre' }]}
          >
            <Input size='large' />
          </Form.Item>
        </div>
        <div className='col-lg-4'>
          <Form.Item
            label='Niveau'
            name='level'
            rules={[
              { required: true, message: 'Veuillez sélectionner un niveau' },
            ]}
          >
            <Radio.Group size='large'>
              <Radio.Button value='Debutant'>Débutant</Radio.Button>
              <Radio.Button value='Intermediaires'>Intermédiaires</Radio.Button>
              <Radio.Button value='Avance'>Avancé</Radio.Button>
            </Radio.Group>
          </Form.Item>
        </div>
        <div className='col-lg-3'>
          <Form.Item
            label='Duration'
            name='practice_duration'
            rules={[{ required: true, message: 'Veuillez fournir une durée' }]}
          >
            <TimePicker
              className='w-100'
              size='large'
              placeholder='Sélectionner la durée'
            />
          </Form.Item>
        </div>
        <Form.Item
          label='Description'
          name='description'
          rules={[
            { required: true, message: 'Veuillez fournir une description' },
          ]}
        >
          <Input.TextArea size='large' />
        </Form.Item>
        <div className='col-lg-4'>
          <Form.Item
            label='Langue'
            name='test_language'
            rules={[
              { required: true, message: 'Veuillez sélectionner une langue' },
            ]}
          >
            <Radio.Group size='large'>
              <Radio.Button value='french'>Français</Radio.Button>
              <Radio.Button value='english'>Anglais</Radio.Button>
            </Radio.Group>
          </Form.Item>
        </div>
        <div className='col-lg-4'>
          <Form.Item label={'Contenu'}>
            <UploadInput />
          </Form.Item>
        </div>
        <div className='col-lg-4'>
          <Form.Item label={'Couverture'}>
            <UploadInput />
          </Form.Item>
        </div>
        <Form.Item>
          <Button
            size='large'
            onClick={() => {
              form.resetFields();
            }}
          >
            Annuler
          </Button>

          <Button
            type='primary'
            size='large'
            className='mx-2'
            htmlType='submit'
          >
            Soumettre
          </Button>
          {loader && <Spin />}
        </Form.Item>
      </Form>
    </div>
  );
};

export default PracticeTestFromBeta;
