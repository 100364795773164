import React from 'react';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';

const WarningModal = () => {
  const [isModalOpen, setIsModalOpen] = React.useState(
    localStorage.getItem('warning') === 'false' ? false : true
  );

  const showModal = () => {
    setIsModalOpen(false);
    localStorage.setItem('warning', false);
  };
  return (
    <Modal isOpen={isModalOpen} size='xl'>
      <ModalBody>
        <div className='bg-warning bg-opacity-25 mb-4 rounded-3 shadow-sm p-4'>
          <h4 className='fw-bold mb-3 text-warning'>
            <span className='pb-1 px-4'>
              Important : À lire attentivement avant de commencer les tests !
            </span>
          </h4>
          <p className='px-4'>
            Notre plateforme utilise des fonctionnalités pour détecter les
            tentatives de triche lors des tests : <br />
            1- N'ouvrez pas d'autres fenêtres dans votre navigateur.
            <br /> 2- Ne quittez pas la fenêtre du test.
            <br /> 3- N'effectuez pas de copier-coller à partir d'autres
            sources.
          </p>
          <p className='px-4'>
            Toutes ces actions sont considérées comme des tentatives de triche,
            et les candidats qui les commettent seront automatiquement éliminés
            du processus de test.
          </p>
          <p className='px-4'>
            Soyez attentif à ces règles et passez les tests de manière intègre.
            Mettez en valeur vos connaissances et votre expertise réelle lors
            des évaluations.
          </p>
          <p className='px-4'>Bonne chance !</p>
        </div>
        <div className='bg-warning bg-opacity-25 mb-4 rounded-3 shadow-sm p-4'>
          <h4 className='fw-bold mb-3'>
            <span className='pb-1 px-4' style={{ color: '#e30613' }}>
              Notification importante :
            </span>
          </h4>
          <p className='px-4'>
            Veuillez noter qu'une fois que vous aurez cliqué sur le test, le
            compte à rebours sera enclenché. Soyez prêt(e) à commencer dès que
            vous aurez lancé le test.
          </p>
        </div>
        <div className='bg-success bg-opacity-25 mb-4 rounded-3 shadow-sm p-4'>
          <h4 className='fw-bold mb-3'>
            <span className='pb-1 px-4'>Accès aux tests :</span>
          </h4>
          <p className='px-4'>
            Les tests techniques seront disponibles sur cette page à partir de
            la date qui vous a été communiquée par e-mail. Veuillez donc
            rafraîchir la page à ce moment-là afin de pouvoir les visualiser.
          </p>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color='primary' onClick={showModal}>
          J'accepte
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default WarningModal;
