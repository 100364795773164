import axios from "../utils/axios";

const testURL = process.env.REACT_APP_DB_URL + "/tests";
const loginURL = process.env.REACT_APP_DB_URL + "/auth";
const resultURL = process.env.REACT_APP_DB_URL + "/result";
const candidatURL = process.env.REACT_APP_DB_URL + "/candidats";
const categorieURL = process.env.REACT_APP_DB_URL + "/categorie";
const surveyURL = process.env.REACT_APP_DB_URL + "/survey";
const factoryURL = process.env.REACT_APP_DB_URL + "/factory";

export const getResultsQuizzes = async () =>
  axios.get(factoryURL + "/results?type=Quiz");
export const getResultsPractice = async () =>
  axios.get(factoryURL + "/results?type=Practice");
// API TEST CALLS
export const getTest = async (id) => axios.get(`${testURL}/${id}`);
export const getTests = async () => axios.get(testURL);
export const getTestsClients = async () =>
  axios.get(`${testURL}/clients/tests`);
export const createTest = async (test) => axios.post(testURL, test);
export const updateTest = async (id, test) =>
  axios.patch(`${testURL}/${id}`, test);
export const showAll = async () => axios.patch(`${testURL}/visiblity/show`);
export const hideAll = async () => axios.patch(`${testURL}/visiblity/hide`);

// API CANDIDAT CALLS
export const createCandidat = async (candidat) =>
  axios.post(`${candidatURL}/createAndMail`, candidat);
export const getCandidats = async () => axios.get(candidatURL);
export const getCandidat = async (id) => axios.get(`${candidatURL}/` + id);
export const addMultiTest = async (id, test) =>
  axios.patch(`${candidatURL}/${id}/add-test`, test);
export const updateCandidat = async (id, candidat) =>
  axios.patch(`${candidatURL}/${id}`, candidat);
export const inviteInterview = async (id, interview) =>
  axios.patch(`${candidatURL}/${id}/updateStatus`, interview);
export const inviteTest = async (id_candidat, id_test) =>
  axios.post(`${candidatURL}/invite/${id_candidat}/to/${id_test}`);

// API AUTHENTICATION CALLS
export const loginAccount = async (login) =>
  axios.post(`${loginURL}/login`, login);
export const ChangePassword = async (id, newPass) =>
  axios.patch(`${loginURL}/update/${id}`, newPass);
export const ForgotPassword = async (email) =>
  axios.post(`${loginURL}/forgot-password`, email);
export const restorPassword = async (id, newPass) =>
  axios.post(`${loginURL}/reset-password/${id}`, newPass);
export const createAdmin = async (admin) =>
  axios.post(`${loginURL}/create_admin`, admin);

// API RESULT CALLS
export const submitResult = async (id, id_candidat) =>
  axios.patch(`${resultURL}/${id}/from/${id_candidat}`);
export const getResults = async () => axios.get(resultURL);
export const getResultsTech = async () =>
  axios.get(`${resultURL}/tech/results`);
export const getQuestionAnswer = async (id_test, id_candidat) =>
  axios.get(`${resultURL}/${id_test}/answers/${id_candidat}`);
export const getTopRanking = async () => axios.get(`${resultURL}/ranking/all`);

// API CATEGORIE CALLS
export const createCategorie = async (cat) =>
  axios.post(`${categorieURL}/`, cat);
export const fetchCategories = async () => axios.get(`${categorieURL}/`);
export const deleteCategorie = async (id) =>
  axios.delete(`${categorieURL}/delete/${id}`);
export const updateCategorie = async (id, cat) =>
  axios.patch(`${categorieURL}/${id}`, cat);

// API STATS CALLS
export const saveSurvey = async (userId, testId) =>
  axios.post(`${surveyURL}`, { userId: userId, testId: testId });
