import { Button, message, Upload } from "antd";

const UploadInput = ({ setFile }) => {
  const handleUpload = async (info) => {
    let fileList = [...info.fileList];
    fileList = fileList.slice(-1);

    fileList.forEach(function (file, index) {
      let reader = new FileReader();
      reader.onload = (e) => {
        if (file.size < 2000000) {
          if (
            file.type === "application/pdf" ||
            file.type === "image/jpg" ||
            file.type === "image/jpeg" ||
            file.type === "image/png" ||
            file.type === "application/zip"
          ) {
            file.base64 = e.target.result;
            setFile(file.base64);
          } else {
            info = null;
            message.error(
              "Seuls les fichiers PDF, JPG, JPEG, PNG et ZIP sont autorisés"
            );
          }
        } else {
          info = null;
          message.error("Le fichier ne doit pas dépasser 2 Mo");
        }
      };
      reader.readAsDataURL(file.originFileObj);
    });
  };

  return (
    <Upload
      type="file"
      style={{ marginBottom: "10px", width: "100%" }}
      maxCount={1}
      onChange={handleUpload}
      accept="image/jpg, image/jpeg ,image/png"
      beforeUpload={() => false}
    >
      <Button size="large" style={{ width: "100%" }}>
        Upload (Max: 1)
      </Button>
    </Upload>
  );
};

export default UploadInput;
