import { Layout, Menu, theme } from "antd";
import React from "react";
import { Link, Outlet } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHouse,
  faScrewdriverWrench,
  faUsers,
  faSquarePollHorizontal,
  faSquarePollVertical,
  faFileCirclePlus,
  faFlaskVial,
  faCubes,
  faStopwatch,
  faCalendarDays,
  faRightFromBracket,
} from "@fortawesome/free-solid-svg-icons";
const { Header, Content, Footer, Sider } = Layout;

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

const Backoffice = () => {
  const items = [
    getItem(
      <Link className="text-decoration-none fw-bold" to="/administration">
        Accueil
      </Link>,
      "1",
      <FontAwesomeIcon icon={faHouse} />
    ),
    getItem(
      <Link className="text-decoration-none fw-bold" to="/administration/form">
        Nouveau Admin
      </Link>,
      "2",
      <FontAwesomeIcon icon={faScrewdriverWrench} />
    ),
    getItem(
      <Link
        className="text-decoration-none fw-bold"
        to="/administration/candidats/list"
      >
        Profils
      </Link>,
      "3",
      <FontAwesomeIcon icon={faUsers} />
    ),
    getItem(
      <Link
        className="text-decoration-none fw-bold"
        to="/administration/results/pratique"
      >
        Resultats Pratique
      </Link>,
      "4",
      <FontAwesomeIcon icon={faSquarePollHorizontal} />
    ),
    getItem(
      <Link
        className="text-decoration-none fw-bold"
        to="/administration/results/theorique"
      >
        Resultats Théorique
      </Link>,
      "5",
      <FontAwesomeIcon icon={faSquarePollVertical} />
    ),
    getItem(
      <Link
        className="text-decoration-none fw-bold"
        to="/administration/test/form/beta"
      >
        Nouveau Test
      </Link>,
      "6",
      <FontAwesomeIcon icon={faFileCirclePlus} />
    ),
    getItem(
      <Link
        className="text-decoration-none fw-bold"
        to="/administration/test/list"
      >
        Tests Disponibles
      </Link>,
      "7",
      <FontAwesomeIcon icon={faFlaskVial} />
    ),
    getItem(
      <Link className="text-decoration-none fw-bold" to="#">
        Collections
      </Link>,
      "8",
      <FontAwesomeIcon icon={faCubes} />
    ),
    getItem(
      <Link
        className="text-decoration-none fw-bold"
        to="/administration/session/form"
      >
        Nouvelle Session
      </Link>,
      "9",
      <FontAwesomeIcon icon={faStopwatch} />
    ),
    getItem(
      <Link
        className="text-decoration-none fw-bold"
        to="/administration/sessions/list"
      >
        Sessions List
      </Link>,
      "10",
      <FontAwesomeIcon icon={faCalendarDays} />
    ),
    getItem(
      <Link className="text-decoration-none fw-bold" to="/login">
        Se déconnecter
      </Link>,
      "11",
      <FontAwesomeIcon icon={faRightFromBracket} />
    ),
  ];
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  return (
    <Layout hasSider>
      <Sider
        style={{
          overflow: "auto",
          height: "100vh",
          position: "fixed",
          left: 0,
          top: 0,
          bottom: 0,
        }}
      >
        <div className="demo-logo-vertical" />
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={["4"]}
          items={items}
        />
      </Sider>
      <Layout
        className="site-layout d-flex flex-column justify-content-between min-vh-100"
        style={{
          marginLeft: 200,
        }}
      >
        <Header
          style={{
            padding: 0,
            background: colorBgContainer,
          }}
        />
        <Content
          style={{
            margin: "24px 16px 0",
            overflow: "initial",
          }}
        >
          <div
            style={{
              padding: 24,
              minHeight: "70vh",
            }}
            className="bg-white shadow-sm rounded-4"
          >
            <Outlet />
          </div>
        </Content>
        <Footer
          className="bg-white"
          style={{
            textAlign: "center",
          }}
        >
          Screening ©2023 Created by Neopolis-Development
        </Footer>
      </Layout>
    </Layout>
  );
};
export default Backoffice;
