import { Form } from "antd";
import React from "react";
import LOGO from "../../Assets/logo_neopolis-dev.png";

const AdminPDF = ({ data }) => {
  return (
    <div className="bg-white min-vh-100 vh-100 h-100">
      <div className="container py-5">
        <div className="d-flex flex-column justify-content-between px-5">
          <div>
            <h3 className="text-center mb-5">Description Admin</h3>
            <Form
              labelCol={{
                xs: { span: 24 },
                sm: { span: 12 },
                md: { span: 8 },
                lg: { span: 5 },
              }}
              wrapperCol={{
                xs: { span: 24 },
                sm: { span: 12 },
                md: { span: 12 },
                lg: { span: 15 },
              }}
              layout="horizontal"
              size="middle"
            >
              <Form.Item label="Compte">
                <br />
                <h6 className="fw-light ">
                  Nom d'utilisateur :
                  <span className="mx-3 fw-bold">{data.fullName}</span>
                </h6>
                <h6 className="fw-light ">
                  Email :<span className="mx-3 fw-bold">{data.email}</span>
                </h6>
                <h6 className="fw-light ">
                  Mot de passe :
                  <span className="mx-3 fw-bold">{data.password}</span>
                </h6>
                <h6 className="fw-light ">
                  Role :<span className="mx-3 fw-bold">{data.Role}</span>
                </h6>
              </Form.Item>

              <Form.Item label="Description">
                <br />
                <h6 className="">
                  - Vous pouvez consulter toutes les données relatives aux
                  candidats, aux tests, aux catégories et aux résultats des
                  tests.
                </h6>
                <h6 className="">
                  - Vous pouvez modifier les données des catégories, des tests,
                  des candidats et de la catégorie.
                </h6>
                <h6 className="">
                  - Vous pouvez ajouter de nouveaux tests et catégories.
                </h6>
                <h6 className="">
                  - Vous pouvez partager un lien de tests ou envoyer des tests
                  aux candidats par e-mail, et vous pouvez masquer et afficher
                  les tests.
                </h6>
              </Form.Item>
            </Form>
          </div>
          <div
            className="d-flex justify-content-end"
            style={{ marginTop: "400px" }}
          >
            <img src={LOGO} alt="neo-logo" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminPDF;
