import React from "react";
import { Card, Form, Input, Button, Typography, message } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { restorPassword } from "../../Api";
import Logo from "../../Assets/logo_neopolis-dev.png";

const { Title, Text } = Typography;

const ResetPassword = () => {
  const [form] = Form.useForm();
  const Navigate = useNavigate();
  const { id } = useParams();

  const onFinish = async (formValues) => {
    try {
      const { data } = await restorPassword(id, formValues);
      if (data.status === "success") {
        message.success("Votre mot de passe a été réinitialisé avec succès", 5);
        setTimeout(() => {
          Navigate("/login");
        }, 5000);
      }
    } catch (error) {
      message.error(error.response.data.message);
    }
  };

  return (
    <div className="row mx-0 justify-content-center pt-5">
      <div className="col-xl-5 col-lg-6 col-md-8 col-sm-12">
        <Card
          style={{ boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" }}
          title={
            <Title level={4} style={{ textAlign: "center" }}>
              Réinitialiser le Mot de passe
            </Title>
          }
          actions={[
            <div className="d-flex justify-content-between mx-5">
              <img
                src={Logo}
                style={{ height: "35px" }}
                alt="Logo"
                onClick={() => {
                  Navigate("/");
                }}
              />
              <Text type="primary" className="mt-2" strong>
                ©2022 Neopolis-dev
              </Text>
            </div>,
          ]}
        >
          <Form
            form={form}
            labelCol={{
              span: 9,
            }}
            wrapperCol={{
              span: 12,
            }}
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            autoComplete="off"
          >
            <Form.Item
              name="password"
              label="Nouveau mot de passe"
              rules={[
                {
                  required: true,
                  message: "Veuillez saisir votre nouveau mot de passe",
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              name="passwordConfirm"
              label="Confirmé le mot de passe"
              rules={[
                {
                  required: true,
                  message: "Veuillez saisir votre nouveau mot de passe",
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              wrapperCol={{
                lg: { offset: 9 },
                md: { offset: 9 },
                sm: { offset: 9 },
                xs: { offset: 0 },
                span: 13,
              }}
            >
              <Button type="primary" htmlType="submit">
                Réinitialiser!
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default ResetPassword;
