import React from "react";
import { Menu, Dropdown, Typography, Modal } from "antd";
import { useDispatch } from "react-redux";
import { InformCandidat } from "../../../Actions/candidat";
import { getTests } from "../../../Actions/test";
import { DownOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import DatePickerForm from "../DatePickerForm";
import { useNavigate } from "react-router-dom";

const { Text } = Typography;
const { confirm } = Modal;
function DropdownActions({ candidat }) {
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const InviteFunction = (id, data) => {
    dispatch(InformCandidat(id, data));
  };

  const handleCandidatStorage = () => {
    localStorage.setItem("candidat", JSON.stringify(candidat));
  };

  const menu = (
    <Menu
      items={[
        {
          key: "1",
          label: (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => {
                confirm({
                  title: "Vous souhaitez inviter ce candidat à un entretien ?",
                  icon: <ExclamationCircleOutlined />,
                  content: (
                    <DatePickerForm
                      candidat={candidat}
                      TriggerFunction={InviteFunction}
                    />
                  ),
                });
              }}
            >
              Inviter à l’entrevue
            </a>
          ),
        },
        {
          key: "3",
          label: (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e) => {
                e.preventDefault();
                dispatch(getTests());
                handleCandidatStorage();
                Navigate("/administration/invitation/");
              }}
            >
              Envoyer un test
            </a>
          ),
        },
      ]}
    />
  );

  return (
    <Dropdown overlay={menu} placement="bottomLeft">
      <Text className="hoverText" strong>
        Plus <DownOutlined />
      </Text>
    </Dropdown>
  );
}

export default DropdownActions;
