import React from "react";
import Logo from "../../Assets/logo_neopolis-dev.png";
import { Button, Form, Input, Typography, message } from "antd";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";

const { Text } = Typography;

const TestTechniqueSubmit = () => {
  const [form] = Form.useForm();
  const Navigate = useNavigate();
  const { id_test } = useParams();
  const { id_candidat } = useParams();

  const submitResult = async (formValues) => {
    try {
      const { data } = await axios.patch(
        `http://localhost:8080/result/${id_test}/from/${id_candidat}`,
        formValues
      );
      if (data) {
        message.success(
          "Merci de soumettre le lien GIT (en mode publique) de votre travail",
          4
        );
        let IDS = JSON.parse(localStorage.getItem("tests_taken"));
        IDS.push({ _id: id_test, submitted_at: Date.now() });
        localStorage.setItem("tests_taken", JSON.stringify(IDS));
        setTimeout(() => {
          Navigate("/");
          window.location.reload();
        }, 4000);
      }
    } catch (error) {}
  };

  return (
    <div className="min-vh-100 h-100 bg-light">
      <div className="container py-5">
        <div className="d-flex justify-content-between pb-5 mx-5">
          <div>
            <img src={Logo} alt="..." />
          </div>
          <div>
            <Text>+216 72 246 535</Text>
            <br />
            <Text>info@neopolis-dev.com</Text>
            <br />
            <Text>Tunisie Nabeul : Résidence Baya Cité El Wafa, 8000</Text>
          </div>
        </div>
        <div className="text-center py-5">
          <div className="border-bottom mx-5">
            <h1 className="text-uppercase h5 fw-light">
              Soumis test technique
            </h1>
          </div>
        </div>
        <div className="mx-5 border-bottom">
          <Form
            form={form}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 16 }}
            layout="horizontal"
            size="middle"
            onFinish={submitResult}
          >
            <Form.Item
              wrapperCol={{
                lg: { offset: 6 },
                md: { offset: 6 },
                sm: { offset: 6 },
                xs: { offset: 0 },
                span: 16,
              }}
            >
              <h6 className="fw-light">
                Soumettre votre travail (Insérer le lien GIT en mode publique)
              </h6>
            </Form.Item>
            <Form.Item
              name="resultLink"
              label="Github repository"
              rules={[
                {
                  required: true,
                  message:
                    "Veuillez entrer le repositorie github qui contient votre travail",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item wrapperCol={{ span: 16, offset: 6 }}>
              <Button type="primary" htmlType="submit">
                Soumettre
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default TestTechniqueSubmit;
