import { Button, Descriptions, Drawer, Typography } from "antd";
import { useState } from "react";
import { CheckCircleTwoTone } from "@ant-design/icons";

const DetailsDrawer = ({ payload }) => {
  console.log(payload);
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Button type="primary" size="small" onClick={showDrawer}>
        Détail
      </Button>
      <Drawer
        title={payload.fullName + " - " + payload.test_name}
        width={720}
        onClose={onClose}
        open={open}
        bodyStyle={{
          paddingBottom: 80,
        }}
      >
        <Descriptions
          title="Généralité"
          className="bg-white p-2 rounded-3"
          column={{
            xxl: 4,
            xl: 4,
            lg: 2,
            md: 1,
            sm: 1,
            xs: 1,
          }}
        >
          <Descriptions.Item label={`Nom et prénom`} span={4} className="pt-2">
            {payload?.fullName}
          </Descriptions.Item>
          <Descriptions.Item label={`Durée`} span={4} className="pt-2">
            {payload?.practice_duration?.hours}:
            {payload?.practice_duration?.minutes}:
            {payload?.practice_duration?.seconds}
          </Descriptions.Item>
          <Descriptions.Item label={`Quiz`} span={4} className="pt-2">
            {payload?.test_name}
          </Descriptions.Item>
        </Descriptions>
        <Descriptions
          title="Résultat"
          className="bg-light p-2 rounded-3"
          column={{
            xxl: 4,
            xl: 4,
            lg: 2,
            md: 1,
            sm: 1,
            xs: 1,
          }}
        >
          <Descriptions.Item span={4}>
            <Typography.Paragraph
              copyable={
                payload?.practice_code_result
                  ? payload?.practice_code_result
                  : "N/A"
              }
              style={{ whiteSpace: "pre-wrap" }}
            >
              {payload?.practice_code_result
                ? payload?.practice_code_result
                : "N/A"}
            </Typography.Paragraph>
          </Descriptions.Item>
        </Descriptions>
      </Drawer>
    </>
  );
};
export default DetailsDrawer;
