import { Button, Form, Radio } from "antd";
import React, { useState } from "react";
import FormQuiz from "../Dashboard/Components/FormQuiz";
import PracticeTestFromBeta from "./PracticeTestFromBeta";

const TestFormBeta = () => {
  const [formType] = Form.useForm();
  const [step, setStep] = useState();
  const [formQuiz] = Form.useForm();
  const [formPractice] = Form.useForm();

  const handleFormType = (formValues) => {
    setStep(formValues.test_type);
  };

  return (
    <div className="p-3 bg-white container">
      <h4>Nouvelle test</h4>
      {!step && (
        <Form form={formType} onFinish={handleFormType}>
          <div className="d-flex justify-content-center align-items-center flex-column">
            <Form.Item name="test_type">
              <Radio.Group className="d-flex" buttonStyle="solid">
                <Radio.Button
                  value="Quiz"
                  style={{ width: "9rem", height: "9rem" }}
                  className="d-flex align-items-center  justify-content-center"
                >
                  Quiz
                </Radio.Button>
                <Radio.Button
                  value="Practice"
                  style={{ width: "9rem", height: "9rem" }}
                  className="d-flex align-items-center  justify-content-center"
                >
                  Pratique
                </Radio.Button>
              </Radio.Group>
            </Form.Item>
          </div>
          <div className="d-flex  d-flex justify-content-evenly">
            <Button size="large" disabled={true}>
              Précédent
            </Button>
            <Button size="large" type="primary" htmlType="submit">
              Suivant
            </Button>
          </div>
        </Form>
      )}
      {step === "Quiz" && <FormQuiz form={formQuiz} />}
      {step === "Practice" && <PracticeTestFromBeta form={formPractice} />}
    </div>
  );
};

export default TestFormBeta;
