import * as api from "../Api";

export const createCategorie = (cat) => async (dispatch) => {
  try {
    const { data } = await api.createCategorie(cat);
    dispatch({ type: "CREATE_CAT", payload: data });
  } catch (error) {
    console.log(error);
  }
};

export const fetchCategories = () => async (dispatch) => {
  try {
    const { data } = await api.fetchCategories();
    dispatch({ type: "FETCH_CATS", payload: data });
  } catch (error) {
    console.log(error);
  }
};

export const updateCategorie = (id, cat) => async (dispatch) => {
  try {
    const { data } = await api.updateCategorie(id, cat);
    dispatch({ type: "UPDATE_CAT", payload: data });
  } catch (error) {
    console.log(error);
  }
};

export const deleteCategorie = (id) => async (dispatch) => {
  try {
    const { data } = await api.deleteCategorie(id);
    dispatch({ type: "DELETE_CAT", payload: id });
  } catch (error) {
    console.log(error);
  }
};
