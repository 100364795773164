import * as api from "../Api";
import {
  CREATE_MAIL_CANDIDAT,
  FETCH_ALL,
  SUBMIT_RESULT,
  UPDATE_CANDIDAT,
  UPDATE_SINGLE_CANDIDAT,
  GET_ONE,
} from "../Constants/actionsType";

export const createAndMail = (candidat) => async (dispatch) => {
  try {
    const { data } = await api.createCandidat(candidat);
    dispatch({ type: CREATE_MAIL_CANDIDAT, payload: data });
  } catch (error) {
    console.log(error.message);
  }
};

export const getCandidats = () => async (dispatch) => {
  try {
    const { data } = await api.getCandidats();
    const result = data.map((res) => {
      if (!res.Institute) {
        res.Institute = "N/A";
      }
      return res;
    });
    dispatch({ type: FETCH_ALL, payload: result });
  } catch (error) {
    console.log(error.message);
  }
};

export const getCandidat = (id) => async (dispatch) => {
  try {
    const { data } = await api.getCandidat(id);
    dispatch({ type: GET_ONE, payload: data });
  } catch (error) {
    console.log(error.message);
  }
};

export const updateCandidat = (id, candidat) => async (dispatch) => {
  try {
    const { data } = await api.updateCandidat(id, candidat);
    dispatch({ type: UPDATE_CANDIDAT, payload: data });
  } catch (error) {
    console.log(error.message);
  }
};

export const updateSingleCandidat = (id, candidat) => async (dispatch) => {
  try {
    const { data } = await api.updateCandidat(id, candidat);
    dispatch({ type: UPDATE_SINGLE_CANDIDAT, payload: data });
  } catch (error) {
    console.log(error.message);
  }
};

export const submitResult = (id, id_candidat) => async (dispatch) => {
  try {
    const { data } = await api.submitResult(id, id_candidat);
    dispatch({ type: SUBMIT_RESULT, payload: data });
  } catch (error) {
    console.log(error.message);
  }
};

export const InformCandidat = (id, interview) => async (dispatch) => {
  try {
    const { data } = await api.inviteInterview(id, interview);
    dispatch({ type: UPDATE_CANDIDAT, payload: data });
  } catch (error) {
    console.log(error.message);
  }
};
