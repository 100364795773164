import { Button, Empty, Input, Popconfirm, Typography, message } from "antd";
import JSONInput from "react-json-editor-ajrm";
import locale from "react-json-editor-ajrm/locale/fr";
import React, { useState } from "react";
import axiosInstance from "../../../utils/axios";

const FormQuiz = ({ form }) => {
  const [data, setData] = useState({});

  const handleSave = async () => {
    try {
      const res = await axiosInstance.post("/tests", data);
      message.success("Quiz enregistré avec succès");
      setData({});
    } catch (error) {
      console.log(error);
      message.error(error?.response?.data?.message);
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <div className="mb-4">
        <Typography.Text style={{ fontSize: "18px" }} strong>
          Nouvelle Quiz (JSON)
        </Typography.Text>
      </div>
      {/* <Form form={form} layout="vertical" className="bg-light p-4 rounded-4">
        <Form.Item label="Titre">
          <Input size="large" />
        </Form.Item>

        <Form.Item label="Description">
          <Input.TextArea size="large" />
        </Form.Item>
        <div className="row justify-content-between">
          <div className="col-lg-3">
            <Form.Item label="Couverture">
              <UploadInput />
            </Form.Item>
          </div>
          <div className="col-lg-3">
            <Form.Item label="Langue">
              <Radio.Group size="large" buttonStyle="solid">
                <Radio.Button value={"Français"}>Français</Radio.Button>
                <Radio.Button value={"Anglais"}>Anglais</Radio.Button>
              </Radio.Group>
            </Form.Item>
          </div>
          <div className="col-lg-4">
            <Form.Item label="Niveau">
              <Radio.Group size="large" buttonStyle="solid">
                <Radio.Button value={"Débutant"}>Débutant</Radio.Button>
                <Radio.Button value={"Intermédiaires"}>
                  Intermédiaires
                </Radio.Button>
                <Radio.Button value={"Avancé"}>Avancé</Radio.Button>
              </Radio.Group>
            </Form.Item>
          </div>
          <div className="col-lg-2">
            <Form.Item label="Coefficient">
              <InputNumber size="large" />
            </Form.Item>
          </div>
        </div>
        <div className="d-flex justify-content-center border-top pt-4">
          <Button className="mx-2" size="large">
            Annuler
          </Button>
          <Button
            className="mx-2"
            type="primary"
            htmlType="submit"
            size="large"
          >
            Sauvegarder
          </Button>
        </div>
      </Form> */}
      <Popconfirm
        onConfirm={handleSave}
        title="Êtes-vous sûr de sauvegarder le nouveau test ?"
      >
        <Button type="primary" className="mb-2">
          Sauvegarder
        </Button>
      </Popconfirm>
      <div className="row">
        <div className="col-lg-5">
          <JSONInput
            id="a_unique_id"
            theme={"dark_vscode_tribute"}
            confirmGood={true}
            waitAfterKeyPress={1000}
            onChange={(e) => {
              if (!e.error) {
                setData(JSON.parse(e.json));
              }
            }}
            locale={locale}
            style={{ width: "100%" }}
          />
        </div>
        <div
          className="col-lg-7 bg-light px-3 py-2 rounded-3"
          style={{ height: "60vh", overflowY: "scroll" }}
        >
          <h4>Aperçu</h4>
          {data ? (
            <div>
              <div className="d-flex justify-content-between">
                <Typography.Text strong>{data?.title}</Typography.Text>
                <Typography.Text strong>{data?.level}</Typography.Text>
              </div>
              {data?.test_language && (
                <Typography.Text strong>
                  Langue: {data?.test_language}
                </Typography.Text>
              )}
              <Typography.Paragraph>{data?.description}</Typography.Paragraph>
              <h5>Quiz</h5>
              {data?.questions ? (
                <div className="">
                  {data?.questions.map((elem, index) => {
                    return (
                      <div className="my-3">
                        <div className="d-flex justify-content-between">
                          <Typography.Text>
                            <Typography.Text strong>
                              Q{index + 1} :{" "}
                            </Typography.Text>
                            {elem?.content}
                          </Typography.Text>
                          <Typography.Text strong>
                            {elem?.duration?.hours}:{elem?.duration?.minutes}:
                            {elem?.duration?.seconds}
                          </Typography.Text>
                        </div>
                        {elem?.type === "multiple_choice" ? (
                          elem?.choices.map((element) => {
                            return (
                              <div className="w-100 d-flex flex-column">
                                <Button
                                  className="my-1 d-flex justify-content-start"
                                  type={
                                    elem.correct_choice.includes(element.index)
                                      ? "primary"
                                      : "default"
                                  }
                                >
                                  {element?.content}
                                </Button>
                              </div>
                            );
                          })
                        ) : (
                          <div>
                            <Input.TextArea />
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              ) : (
                <Empty />
              )}
            </div>
          ) : (
            <Empty />
          )}
        </div>
      </div>
    </div>
  );
};

export default FormQuiz;
