import React, { useRef, useState, useEffect } from "react";
import Highlighter from "react-highlight-words";
import { Table, Space, Typography, Input, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";

import { useNavigate } from "react-router-dom";
import { getResultsQuizzes } from "../../../Api";
import DetailsDrawer from "./DetailsDrawer";
const { Link } = Typography;

const ResultsList = () => {
  const Navigate = useNavigate();
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  useEffect(() => {
    async function fetchData() {
      const { data } = await getResultsQuizzes();
      setResults(data.results);
      setLoading(false);
    }
    fetchData();
  }, []);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Rechercher`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "Nom et Prénom",
      dataIndex: "fullName",
      key: "fullName",
      ...getColumnSearchProps("fullName"),
      render: (_, result) => (
        <Link
          onClick={() => {
            Navigate("/administration/candidat/" + result._id);
          }}
        >
          {result.fullName}
        </Link>
      ),
      fixed: "left",
    },
    {
      title: "Test passé",
      dataIndex: "test_name",
      key: "test_name",
      ...getColumnSearchProps("test_name"),
    },
    {
      title: "Score total",
      dataIndex: "global_score",
      key: "global_score",
      render: (_, record) => (
        <Typography.Text>
          {record?.global_score + " / " + record?.max_score}
        </Typography.Text>
      ),
    },
    {
      title: "Actions",
      render: (_, result) => (
        <Space>
          <DetailsDrawer payload={result} />
        </Space>
      ),
    },
  ];

  return (
    <div className="mt-2">
      <div className="mb-3">
        <div>
          <h4>Liste des résultats théorique</h4>
        </div>
      </div>

      <Table
        loading={loading}
        columns={columns}
        dataSource={results}
        scroll={{ x: "max-content" }}
      />
    </div>
  );
};

export default ResultsList;
