import { combineReducers } from "redux";
import test from "./test";
import candidat from "./candidat";
import results from "./result";
import categories from "./categorie";

export default combineReducers({
  tests: test,
  candidat: candidat,
  results: results,
  categories: categories,
});
