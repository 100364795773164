import { Routes, Route } from "react-router-dom";
import Home from "./Components/Home";
// import Dashboard from './Dashboard';
import Login from "./Components/Login";
import SubmitFrom from "./Components/SubmitForm";
import ManualForm from "./Components/ManualForm";
import ErrorRouter from "./Components/ErrorRouter";
import TestsList from "./Components/TestsList";
import TestTechnique from "./Components/TestTechnique";
import TestTechniqueSubmit from "./Components/TestTechniqueSubmit";
import Profile from "./Components/Profile";
// import { useEffect } from 'react';
import SharedLink from "./Components/SharedLink";
import ForgetPassword from "./Components/ForgetPassword";
import ResetPassword from "./Components/ResetPassword";
import AdminWLCPage from "./Dashboard/Components/AdminWLCPage";
import CandidatsList from "./Dashboard/Components/CandidatsList";
import TestForm from "./Dashboard/Components/TestForm/TestForm";
import ResultsList from "./Dashboard/Components/ResultsList";
import TechResults from "./Dashboard/Components/TechResults/TechResults";
import TestInvitation from "./Dashboard/Components/TestInvitation";
import AdminForm from "./Dashboard/Components/AdminForm";
import GestionCategories from "./Dashboard/Components/GestionCategories/GestionCategories";
import CandidatProfile from "./Dashboard/Components/CandidatProfile";
import TestList from "./Dashboard/Components/TestList";
import TestPAGE from "./Components/TestPAGE/TestPAGE";
import TestFormBeta from "./Pages/TestFormBeta";
import Backoffice from "./Layouts/Backoffice";
import Quiz from "./Components/Quiz/Quiz";
import SessionsList from "./Pages/SessionsList";
import SessionForm from "./Pages/SessionForm";
import PracticeTest from "./Pages/PracticeTest";
function App() {
  const token = localStorage.getItem("token");
  const Role = localStorage.getItem("Role");

  return (
    <Routes>
      <Route path="/" exact element={<Home />} />
      <Route path="/login" exact element={<Login />} />
      <Route
        path="/submit/:id/from/:id_candidat"
        exact
        element={<SubmitFrom />}
      />
      <Route path="/getting-started/" exact element={<ManualForm />} />
      <Route path="/getting-started/:id" exact element={<SharedLink />} />
      <Route
        path="/test/:id_test/to/:id_candidat"
        exact
        element={<TestTechnique />}
      />
      <Route
        path="/test/:id_test/submit_from/:id_candidat"
        exact
        element={<TestTechniqueSubmit />}
      />
      {token && (
        <Route path="/practice/test/:id" exact element={<PracticeTest />} />
      )}
      <Route path="/forget-password" exact element={<ForgetPassword />} />
      <Route path="/reset-password/:id" exact element={<ResetPassword />} />
      {token && <Route path="/tests_list" exact element={<TestsList />} />}
      {token && <Route path="/profile/:id" exact element={<Profile />} />}
      {token && <Route path="/quiz/:id" exact element={<Quiz />} />}

      {token && (
        <Route
          path="/test/:id_test/user/:id_candidat"
          exact
          element={<TestPAGE />}
        />
      )}
      {token && (Role === "SUPER_ADMIN" || Role === "ADMIN") && (
        <Route path="/administration" element={<Backoffice />}>
          <Route path="" element={<AdminWLCPage />} />
          <Route path="session/form" element={<SessionForm />} />
          <Route path="test/form/beta" element={<TestFormBeta />} />
          <Route path="sessions/list" element={<SessionsList />} />
          <Route path="candidats/list" element={<CandidatsList />} />
          <Route path="test/form" element={<TestForm />} />
          <Route path="test/list" element={<TestList />} />
          <Route path="results/theorique" element={<ResultsList />} />
          <Route path="results/pratique" element={<TechResults />} />
          <Route path="invitation" element={<TestInvitation />} />
          <Route path="form" element={<AdminForm />} />
          <Route path="categories" element={<GestionCategories />} />
          <Route path="candidat/:id" element={<CandidatProfile />} />
        </Route>
      )}

      <Route path="*" exact element={<ErrorRouter />} />
    </Routes>
  );
}

export default App;
