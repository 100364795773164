import * as api from "../Api";
import { CREATE_TEST, FETCH_ALL, UPDATE_TEST } from "../Constants/actionsType";

export const createTest = (test) => async (dispatch) => {
  try {
    const { data } = await api.createTest(test);
    dispatch({ type: CREATE_TEST, payload: data });
  } catch (error) {
    console.log(error.message);
  }
};

export const showAll = () => async (dispatch) => {
  try {
    const { data } = await api.showAll();
    dispatch({ type: "SHOW_ALL", payload: data });
  } catch (error) {
    console.log(error.message);
  }
};

export const hideAll = () => async (dispatch) => {
  try {
    const { data } = await api.hideAll();
    dispatch({ type: "HIDE_ALL", payload: data });
  } catch (error) {
    console.log(error.message);
  }
};

export const getTests = () => async (dispatch) => {
  try {
    const { data } = await api.getTests();
    dispatch({ type: FETCH_ALL, payload: data });
  } catch (error) {
    console.log(error.message);
  }
};

export const getTestsClients = () => async (dispatch) => {
  try {
    const { data } = await api.getTestsClients();
    dispatch({ type: FETCH_ALL, payload: data });
  } catch (error) {
    console.log(error.message);
  }
};

export const updateTest = (id, test) => async (dispatch) => {
  try {
    const { data } = await api.updateTest(id, test);
    dispatch({ type: UPDATE_TEST, payload: data });
  } catch (error) {
    console.log(error.message);
  }
};
