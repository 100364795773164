import React from 'react';
import classes from './Quiz.module.css';
import Timer from './Timer/Timer';
import Question from './Question/Question';
// import { data } from './data.js';
import { Row, Col } from 'reactstrap';
import { useParams, useNavigate } from 'react-router-dom';
import axios from '../../utils/axios';
import Swal from 'sweetalert2';
import { notification } from 'antd';
import { EyeOutlined } from '@ant-design/icons';

const Quiz = (props) => {
  const testURL = process.env.REACT_APP_DB_URL;
  const Navigate = useNavigate();
  const [currentQuestion, setCurrentQuestion] = React.useState(0);
  const [surveyTimes, setSurveyTimes] = React.useState(true);
  const [api, contextHolder] = notification.useNotification();
  const [answares, setAnswares] = React.useState([]);
  const [timeLeft, setTimeLeft] = React.useState({});
  const [questionAnswares, setQuestionAnswares] = React.useState(null);
  const { id } = useParams();
  const [data, setData] = React.useState(null);

  const incrementQuestion = () => {
    if (currentQuestion < data?.questions.length - 1) {
      setCurrentQuestion((prev) => prev + 1);
      setAnswares((prev) => [
        ...prev,
        {
          ...questionAnswares,
          answer_duration: {
            hours: String(timeLeft?.hours).padStart(2, '0'),
            minutes: String(timeLeft?.minutes).padStart(2, '0'),
            seconds: String(60 - timeLeft?.seconds).padStart(2, '0'),
          },
        },
      ]);
      setQuestionAnswares(null);
    } else if (currentQuestion === data?.questions.length - 1) {
      // Swal.fire({
      //   position: 'top-end',
      //   icon: 'warning',
      //   title:
      //     'Vos réponses ont été enregistrées avec succès !\nVous serez maintenant redirigé(e) vers la page des tests.',
      //   showConfirmButton: false,
      //   timer: 6000,
      //   allowOutsideClick: false,
      // });
      sendAnswares(false);
    }
  };

  function shuffleArray(array) {
    // Copy the original array to avoid modifying the input array
    const shuffledArray = [...array];

    // Loop through the array starting from the last element
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      // Generate a random index between 0 and i
      const randomIndex = Math.floor(Math.random() * (i + 1));

      // Swap the current element with the randomly selected element
      [shuffledArray[i], shuffledArray[randomIndex]] = [
        shuffledArray[randomIndex],
        shuffledArray[i],
      ];
    }

    return shuffledArray;
  }

  React.useEffect(() => {
    // document.addEventListener('contextmenu', function (event) {
    //   event.preventDefault();
    // });

    if (id) {
      axios
        .get(`${testURL}/tests/${id}`)
        .then((res) => {
          if (res?.data?.status === 'success') {
            // setData(res?.data?.quiz);
            let _quiz = res?.data?.quiz;
            _quiz.questions = shuffleArray(_quiz.questions);
            // console.log(_quiz);
            setData(_quiz);
            axios
              .post(`${testURL}/factory/${id}`, {})
              .then(() => {})
              .catch(() => {});
          }
        })

        .catch((err) => {
          console.log(err);
          Navigate('/tests_list');
          Swal.fire({
            // position: 'top-end',
            icon: 'info',
            title:
              'Désolé, mais vous avez déjà passé ce test !\nVeuillez poursuivre avec les autres tests disponibles.',
            showConfirmButton: false,
            timer: 5000,
            // allowOutsideClick: false,
          });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  React.useEffect(() => {
    window.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'hidden') {
        if (!surveyTimes) {
          sendAnswares(true);
        }
        if (surveyTimes) {
          setSurveyTimes(false);
          api['warning']({
            message:
              'Attention : Il est strictement interdit de quitter la page du test.',
            icon: <EyeOutlined />,
            style: {
              backgroundColor: '#fff2f0',
              border: '1px solid #db4654',
              borderRadius: '13px',
            },
            description:
              'Si vous quittez à nouveau la page, votre test sera automatiquement terminé.',
            duration: 0,
          });
        }
      }
    });
  }, [surveyTimes]);

  const sendAnswares = (_condition) => {
    if (id) {
      axios
        .patch(`${testURL}/factory/result/${id}`, {
          result: [
            ...answares,
            {
              ...questionAnswares,
              answer_duration: {
                hours: String(timeLeft?.hours).padStart(2, '0'),
                minutes: String(timeLeft?.minutes).padStart(2, '0'),
                seconds: String(60 - timeLeft?.seconds).padStart(2, '0'),
              },
            },
          ],
        })
        .then((res) => {
          if (res?.data?.status === 'success') {
            Navigate('/tests_list');
            if (_condition) {
              Swal.fire({
                // position: 'top-end',
                width: '40%',
                icon: 'info',
                title:
                  'Attention : Vous avez quitté la page du test une deuxième fois.\nConformément aux règles établies, votre test est maintenant terminé.',

                showConfirmButton: true,
                // timer: 6000,
                allowOutsideClick: false,
              });
            } else {
              Swal.fire({
                // position: 'top-end',
                width: '40%',
                icon: 'success',
                title:
                  'Vos réponses ont été enregistrées avec succès !\nVous serez maintenant redirigé(e) vers la page des tests.',
                showConfirmButton: false,
                timer: 6000,
                allowOutsideClick: false,
              });
            }
            // setTimeout(() => {
            //   Navigate('/tests_list');
            // }, 4000);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  return (
    <div className={classes.wrapper}>
      {data && (
        <>
          {contextHolder}
          <Row className={classes.counterBar}>
            <Col className={classes.quizTitle}>
              <h4>
                {data?.title} - {data?.level}
              </h4>
            </Col>
            <Col className={classes.step_progress}>
              <span className='text-capitalize'>
                question {currentQuestion + 1} /{data?.questions.length}
              </span>
              <div className='progress rounded-pill'>
                <div
                  className='progress-bar rounded-pill'
                  role='progressbar'
                  style={{
                    width:
                      ((currentQuestion + 1) / data?.questions.length) * 100 +
                      '%',
                  }}
                  aria-valuenow='25'
                  aria-valuemin='0'
                  aria-valuemax='100'
                ></div>
              </div>
            </Col>
            <Col>
              <Timer
                timeLeft={timeLeft}
                setTimeLeft={setTimeLeft}
                hours1={data?.questions[currentQuestion]?.duration?.hours}
                minutes1={data?.questions[currentQuestion]?.duration?.minutes}
                seconds1={data?.questions[currentQuestion]?.duration?.seconds}
                currentQuestion={currentQuestion}
                incrementQuestion={incrementQuestion}
              />
            </Col>
          </Row>
          <div className={classes.quiz}>
            <Question
              timeLeft={timeLeft}
              setQuestionAnswares={setQuestionAnswares}
              sendAnswares={sendAnswares}
              quizLength={data?.questions.length === currentQuestion + 1}
              incrementQuestion={incrementQuestion}
              data={data?.questions[currentQuestion]}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default Quiz;
