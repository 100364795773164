import { Modal, Form, Input, Button, Typography } from "antd";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { updateCandidat } from "../../../Actions/candidat";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

const { Text } = Typography;

const CandidatModal = ({ id, candidat }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  form.setFieldsValue(candidat);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = (formValues) => {
    dispatch(updateCandidat(id, formValues));
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Text
        strong
        className='hoverText'
        type='success'
        id={id}
        onClick={showModal}
      >
        Edit
      </Text>
      <Modal
        title={`Editer Candidat '${candidat.fullName}' ?`}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={[
          <div className='d-flex justify-content-start'>
            <Button key='back' onClick={handleCancel}>
              Cancel
            </Button>
          </div>,
        ]}
      >
        <Form
          form={form}
          labelCol={{ span: 7 }}
          wrapperCol={{ span: 15 }}
          layout='horizontal'
          size='middle'
          onFinish={handleOk}
        >
          <Form.Item
            name='fullName'
            label='Nom et Prenom'
            rules={[{ required: true }]}
          >
            <Input allowClear />
          </Form.Item>
          <Form.Item name='email' label='Email' rules={[{ required: true }]}>
            <Input allowClear />
          </Form.Item>
          <Form.Item
            name='phoneNumber'
            label='Numéro du téléphone'
            rules={[{ required: true }]}
          >
            <PhoneInput defaultCountry='TN' />
          </Form.Item>

          <Form.Item
            wrapperCol={{
              lg: { offset: 7 },
              md: { offset: 7 },
              sm: { offset: 7 },
              xs: { offset: 0 },
              span: 15,
            }}
          >
            <Button type='primary' block htmlType='submit'>
              Editer
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default CandidatModal;
