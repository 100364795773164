import React, { useState } from "react";
import { Button, Form, Input, Typography, Modal } from "antd";
import { updateCategorie } from "../../../Actions/categorie";
import { useDispatch } from "react-redux";

const { Text } = Typography;

const CategorieModel = ({ categorie }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  form.setFieldsValue(categorie);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = (formValues) => {
    dispatch(updateCategorie(categorie._id, formValues));
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Text strong className="hoverText" type="success" onClick={showModal}>
        Modifier
      </Text>
      <Modal
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={[
          <div className="d-flex justify-content-start">
            <Button key="back" onClick={handleCancel}>
              Cancel
            </Button>
          </div>,
        ]}
      >
        <Form
          form={form}
          labelCol={{ span: 7 }}
          wrapperCol={{ span: 15 }}
          layout="horizontal"
          size="middle"
          onFinish={handleOk}
        >
          <Form.Item name="categorie" label="categorie">
            <Input />
          </Form.Item>

          <Form.Item
            wrapperCol={{
              lg: { offset: 7 },
              md: { offset: 7 },
              sm: { offset: 7 },
              xs: { offset: 0 },
              span: 15,
            }}
          >
            <Button type="primary" block htmlType="submit">
              Editer
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default CategorieModel;
