import React from 'react';
import { Col } from 'reactstrap';
import classes from '../../Quiz.module.css';
const AnswareInput = (props) => {
  const { setQuestionAnswares } = props;
  return (
    <Col
      lg='12'
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'self-start',
      }}
    >
      <label className={classes.answareLabel} htmlFor='answareInput'>
        Reponse:
      </label>
      <input
        className={classes.answareInput}
        name='answareInput'
        onChange={(e) => {
          setQuestionAnswares((prev) => {
            return {
              ...prev,
              answer_text: e.target.value,
            };
          });
        }}
      />
    </Col>
  );
};

export default AnswareInput;
