import React from "react";
import { Col } from "reactstrap";
import classes from "../../Quiz.module.css";

const Choices = (props) => {
  const { choice, setQuestionAnswares } = props;
  const [checked, setChecked] = React.useState("");
  const handleChecked = () => {
    setChecked(checked === "active" ? "" : "active");
    if (checked === "active") {
      setQuestionAnswares((prev) => {
        return {
          ...prev,
          answer_choices: prev?.answer_choices.filter((c) => c !== choice?._id),
        };
      });
    } else {
      setQuestionAnswares((prev) => {
        return {
          ...prev,
          answer_choices: prev?.answer_choices
            ? [...prev?.answer_choices, choice?._id]
            : [choice?._id],
        };
      });
    }
  };
  return (
    <Col lg="6" className={classes.col}>
      <li
        // style={{ cursor: 'pointer', hight: '100%!importatnt' }}
        onClick={handleChecked}
        className={
          "step_1 position-relative d-inline-block animate__animated animate__fadeInRight animate_150ms" +
          " " +
          checked
        }
      >
        <input
          type="checkbox"
          name="stp_1_select_option"
          value="None of the above"
        />
        <label>{choice?.content}</label>
        <span className="position-absolute">{choice?.index}</span>
      </li>
    </Col>
  );
};

export default Choices;
