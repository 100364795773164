import React from "react";
import LostAStro from "../Assets/Cat_astronaut.gif";
import { useNavigate } from "react-router-dom";

const ErrorRouter = () => {
  const Navigate = useNavigate();

  const handleNavigate = () => {
    Navigate("/");
  };
  return (
    <div className='d-flex flex-column mx-auto justify-content-center mt-5 col-lg-4 col-md-6 col-sm-12'>
      <img src={LostAStro} />
      <h4 className='text-center' style={{ color: "#1C538E" }}>
        vous êtes perdu ?
      </h4>
      <button className='cta mx-auto' onClick={handleNavigate}>
        <span>Retour au accueil</span>
        <svg viewBox='0 0 13 10' height='10px' width='15px'>
          <path d='M1,5 L11,5'></path>
          <polyline points='8 1 12 5 8 9'></polyline>
        </svg>
      </button>
    </div>
  );
};

export default ErrorRouter;
