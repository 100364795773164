import { Button, Descriptions, Typography } from "antd";
import React, { useEffect, useState } from "react";
import axiosInstance from "../utils/axios";
import { useNavigate } from "react-router-dom";

const TestPreview = ({ id }) => {
  const Navigate = useNavigate();

  const [test, setTest] = useState({});

  useEffect(() => {
    async function fetchData() {
      const { data } = await axiosInstance.get("/factory/test/" + id);
      setTest(data.test);
      return data;
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="col-lg-5 p-4 rounded-4 bg-dark bg-opacity-25 shadow-sm my-2 d-flex flex-column justify-content-between">
      <div className="d-flex justify-content-center">
        <Typography.Text strong style={{ fontSize: "24px" }}>
          {test?.title}
        </Typography.Text>
      </div>
      <div className="d-flex flex-column">
        <Typography.Text strong style={{ fontSize: "18px" }}>
          Niveau:{" "}
          {test?.level === "Debutant"
            ? "Débutant"
            : test?.level === "Intermediaries"
            ? "Intermédiaires"
            : "Avancé"}
        </Typography.Text>
        <Typography.Text strong style={{ fontSize: "18px" }}>
          Type: {test?.test_type === "Quiz" ? "QCM" : "Pratique"}
        </Typography.Text>
      </div>
      {/* <div className="w-100 text-center p-3">
        <img src={test?.cover} className="rounded-4" style={{ width: "85%" }} />
      </div> */}

      <div className="d-flex mt-2">
        <Button
          size="large"
          onClick={() => {
            if (test?.test_type === "Quiz") {
              Navigate("/quiz/" + id);
            } else {
              Navigate("/practice/test/" + id);
            }
          }}
          type="primary"
        >
          Accéder
        </Button>
      </div>
    </div>
  );
};

export default TestPreview;
