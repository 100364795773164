import React, { useState, useEffect } from "react";
import Navbar from "../Navbar/Navbar";
import TestImg from "../../Assets/About-us-page-amico.svg";
import { fetchCategories } from "../../Api";
import "./styles.css";
import { useNavigate } from "react-router-dom";
import Countdown from "./Countdown/Countdown";
import axiosInstance from "../../utils/axios";
import dayjs from "dayjs";
// import Editor from '@monaco-editor/react';

// import TestPratique from '../TestPratique/TestPratique';
const Home = () => {
  const [session, setSession] = useState([]);
  const [sessionTimer, setSessionTimer] = useState("");
  const [token, setToken] = useState("" || localStorage.getItem("token"));
  const [value, setValue] = useState("");
  const handleEditorChange = (value) => {
    setValue(value);
  };
  const getStoreCategories = async () => {
    try {
      const { data } = await fetchCategories();
      localStorage.setItem("cats", JSON.stringify(data));
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    async function fetchData() {
      const { data } = await axiosInstance.get("/factory/session/me/last");
      setSession(data?.sessions);
      setSessionTimer(dayjs(data?.session.start_date));

      // const minutes = dayjs(data?.sessions[0]?.end_date).diff(
      //   dayjs(data?.sessions[0]?.start_date),
      //   "minutes"
      // );
      // const durationObj = dayjs.duration(minutes, "minutes");
      // const formattedTime = dayjs
      //   .utc(durationObj.as("milliseconds"))
      //   .format("HH:mm:ss");
      // console.log(durationObj);
      // console.log(formattedTime);
    }
    fetchData();
    setToken(localStorage.getItem("token"));
  }, []);

  const Navigate = useNavigate();

  const handleNavigate = () => {
    Navigate("/getting-started/");
  };

  const handleTestList = () => {
    Navigate("/tests_list");
  };
  return (
    <div className="min-vh-100 w-100 bg-light">
      <Navbar />
      <div className="py-5 hexbackground my-5" style={{ maxHeight: "100vh" }}>
        <section className=" text-center container">
          <div className="row py-lg-5">
            <div className="col-lg-6 col-md-8 mx-auto">
              <h1 className="fw-light h1 mb-4 text-uppercase">
                Bienvenue sur la plateforme "Screening" de Neopolis Development{" "}
              </h1>

              {token && (
                <>
                  <h4 style={{ marginTop: "3rem" }}>
                    Votre session de test commence dans:
                  </h4>
                  {sessionTimer && <Countdown date={sessionTimer} />}
                </>
              )}

              <p className="lead text-muted">
                {/* Il s'agit d'une série de tests techniques et théoriques
                soigneusement conçus pour évaluer vos compétences et renforcer
                vos chances de réussir votre candidature ! */}
                <br />
                {!token &&
                  "Vous devez enregistrer tout d’abord vos données avant de commencer et ce en créant un compte utilisateur."}
              </p>
              {token ? (
                <>
                  <button className="cta mx-auto" onClick={handleTestList}>
                    <span>Accéder aux tests</span>
                    <svg viewBox="0 0 13 10" height="10px" width="15px">
                      <path d="M1,5 L11,5"></path>
                      <polyline points="8 1 12 5 8 9"></polyline>
                    </svg>
                  </button>
                </>
              ) : (
                <button className="cta mx-auto" onClick={handleNavigate}>
                  <span>Inscrivez-vous</span>
                  <svg viewBox="0 0 13 10" height="10px" width="15px">
                    <path d="M1,5 L11,5"></path>
                    <polyline points="8 1 12 5 8 9"></polyline>
                  </svg>
                </button>
              )}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Home;
