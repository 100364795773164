import { Empty, Spin } from "antd";
import "bootstrap/dist/css/bootstrap.min.css";
import Navbar from "../Navbar";
import "./styles.css";
import { useEffect, useState } from "react";
import axiosInstance from "../../utils/axios";
import TestPreview from "../../Pages/TestPreview";
import WarningModal from "./WarningModal/WarningModal";
import Timer from "../Quiz/Timer/Timer";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import Countdown from "../Home/Countdown/Countdown";
dayjs.extend(duration);
function TestList() {
  const [session, setSession] = useState([]);
  const [empty, setEmpty] = useState(true);
  const [sessionTimer, setSessionTimer] = useState({});
  const [takenTests, setTakenTests] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      const { data } = await axiosInstance.get("/factory/session/me");
      setSession(data?.sessions);
      setSessionTimer(dayjs(data?.sessions[0].end_date));
      console.log();
      // const minutes = dayjs(data?.sessions[0]?.end_date).diff(
      //   dayjs(data?.sessions[0]?.start_date),
      //   "minutes"
      // );
      // const durationObj = dayjs.duration(minutes, "minutes");
      // const formattedTime = dayjs
      //   .utc(durationObj.as("milliseconds"))
      //   .format("HH:mm:ss");
      // console.log(durationObj);
      // console.log(formattedTime);

      setLoading(false);
    }
    fetchData();

    async function fetchTakenTests() {
      const { data } = await axiosInstance.get("/factory/tests/me");
      setTakenTests(data.tests);
      return data;
    }
    fetchTakenTests();
  }, []);

  useEffect(() => {
    if (session?.length > 0) {
      session.forEach((elem) => {
        elem.id_tests.forEach((test) => {
          if (!takenTests.find((item) => item.id_test === test))
            setEmpty(false);
        });
      });
    }
  }, [session, takenTests]);

  useEffect(() => {
    window.removeEventListener("visibiltychange", null);
  }, []);

  return (
    <div className="min-vh-100 w-100 bg-light ">
      <Navbar />
      <WarningModal />

      <div
        style={{
          transform: "scale(.8)",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <h4 style={{ marginBottom: "1rem" }}>
          Temps restant pour la session de test{" "}
        </h4>
        <Countdown date={sessionTimer} disabledAction={true} />
      </div>
      <div
        className="mt-2 container bg-white p-5 rounded-4"
        style={{ minHeight: "70vh" }}
      >
        <div className="d-flex justify-content-between align-items-center">
          <h4 className="mb-5">Tests disponibles:</h4>
        </div>
        {loading ? (
          <div className="d-flex h-100 mt-5 flex-column justify-content-center">
            <Spin size="large" />
          </div>
        ) : (
          <div className="container row justify-content-evenly">
            {session?.map((elem) => {
              return elem?.id_tests.map((test) => {
                return (
                  !takenTests.find((item) => item.id_test === test) && (
                    <TestPreview key={test} id={test} />
                  )
                );
              });
            })}
            {empty && <Empty />}
          </div>
        )}
      </div>
    </div>
  );
}

export default TestList;
