import { PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  DatePicker,
  Descriptions,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Space,
  Typography,
} from 'antd';
import { useState } from 'react';
const { Option } = Select;
const SessionDetails = ({ name, payload }) => {
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Button type='primary' onClick={showDrawer} size='small'>
        Détail
      </Button>
      <Drawer
        title={'Session de: ' + name}
        width={720}
        onClose={onClose}
        open={open}
        bodyStyle={{
          paddingBottom: 80,
        }}
      >
        <Descriptions
          title='Genéralité'
          className='border-bottom mb-3'
          column={{
            xxl: 3,
            xl: 3,
            lg: 2,
            md: 1,
            sm: 1,
            xs: 1,
          }}
        >
          <Descriptions.Item span={3} label='Durée'>
            {name}
          </Descriptions.Item>
          <Descriptions.Item span={3} label='Langue'>
            {payload.session_language === 'french' ? 'Français' : 'Anglais'}
          </Descriptions.Item>
        </Descriptions>
        <Descriptions
          title='Participant'
          className='border-bottom mb-3'
          column={{
            xxl: 3,
            xl: 3,
            lg: 2,
            md: 1,
            sm: 1,
            xs: 1,
          }}
        >
          <Descriptions.Item span={3} label='Email(s)'>
            <ul>
              {JSON.parse(payload.utils).id_users.map((elem) => {
                return (
                  <li>
                    <Typography.Text>{elem.email}</Typography.Text>
                  </li>
                );
              })}
            </ul>
          </Descriptions.Item>
        </Descriptions>
        <Descriptions
          title='Tests sélectionnés'
          className='border-bottom mb-3'
          column={{
            xxl: 3,
            xl: 3,
            lg: 2,
            md: 1,
            sm: 1,
            xs: 1,
          }}
        >
          <Descriptions.Item span={3} label='Test(s)'>
            <ul>
              {JSON.parse(payload.utils).id_tests.map((elem) => {
                return (
                  <li>
                    <Typography.Text>
                      {elem.title} ({elem.level})
                    </Typography.Text>
                  </li>
                );
              })}
            </ul>
          </Descriptions.Item>
        </Descriptions>
      </Drawer>
    </>
  );
};

export default SessionDetails;
