import React, { useEffect, useState } from 'react';
import Editor from '@monaco-editor/react';
import monacoThemes from 'monaco-themes/themes/themelist';
import { languageOptions } from '../Constants/languageOptions';
import './PracticeTest.css';
import { Button, Select, Typography, message } from 'antd';
import { defineTheme } from '../lib/defineTheme';
import axios from 'axios';

const CodeEditor = ({ setOutputDetails, setValue, value, setProcessing }) => {
  const [code, setCode] = useState('');
  const [theme, setTheme] = useState('active4d');
  const [language, setLanguage] = useState(languageOptions[0].value);
  const [languageId, setLanguageId] = useState(languageOptions[0].id);

  const onChange = (action, data) => {
    switch (action) {
      case 'code': {
        setCode(data);
        break;
      }
      default: {
        console.warn('case not handled!', action, data);
      }
    }
  };

  const onSelectChange = (sl) => {
    console.log('selected Option...', sl);
    const tempId = languageOptions.find((elem) => elem.value === sl);
    setLanguageId(tempId.id);
    setLanguage(sl);
  };

  function handleThemeChange(th) {
    const theme = th;
    console.log('theme...', theme);

    if (['light', 'vs-dark'].includes(theme.value)) {
      setTheme(theme);
    } else {
      defineTheme(theme).then((_) => setTheme(theme));
    }
  }

  const handleCompile = () => {
    setProcessing(true);
    const formData = {
      language_id: languageId,
      // encode source code in base64
      source_code: btoa(code),
      //   stdin: btoa(customInput),
    };
    const options = {
      method: 'POST',
      url: process.env.REACT_APP_RAPID_API_URL,
      params: { base64_encoded: 'true', fields: '*' },
      headers: {
        'content-type': 'application/json',
        'Content-Type': 'application/json',
        // "X-RapidAPI-Host": process.env.REACT_APP_RAPID_API_HOST,
        'X-Auth-Token': process.env.REACT_APP_RAPID_API_KEY,
      },
      data: formData,
    };

    axios
      .request(options)
      .then(function (response) {
        console.log('res.data', response.data);
        const token = response.data.token;
        checkStatus(token);
      })
      .catch((err) => {
        let error = err.response ? err.response.data : err;
        // get error status
        let status = err.response.status;
        console.log('status', status);
        if (status === 429) {
          setProcessing(false);
          console.log('too many requests', status);
          message.warning(
            `Quota of 100 requests exceeded for the Day! Please read the blog on freeCodeCamp to learn how to setup your own RAPID API Judge0!`
          );
        }
        console.log('catch block...', error);
      });
  };

  const checkStatus = async (token) => {
    const options = {
      method: 'GET',
      url: process.env.REACT_APP_RAPID_API_URL + '/' + token,
      params: { base64_encoded: 'true', fields: '*' },
      headers: {
        // "X-RapidAPI-Host": process.env.REACT_APP_RAPID_API_HOST,
        'X-Auth-Token': process.env.REACT_APP_RAPID_API_KEY,
      },
    };
    try {
      let response = await axios.request(options);
      let statusId = response.data.status?.id;

      // Processed - we have a result
      if (statusId === 1 || statusId === 2) {
        // still processing
        setTimeout(() => {
          checkStatus(token);
        }, 2000);
        return;
      } else {
        setOutputDetails(response.data);
        setProcessing(false);
        message.success(`Compiled Successfully!`);
        console.log('response.data', response.data);
        return;
      }
    } catch (err) {
      console.log('err', err);
      setProcessing(false);
    }
  };

  useEffect(() => {
    console.log('Language...', language);
  }, [language]);

  const handleEditorChange = (value) => {
    setValue(value);
    onChange('code', value);
  };
  return (
    <div className='col-lg-8 h-100 d-flex justify-content-between flex-column '>
      {/* <div
        className='bg-dark  forced-left-container p-3'
        style={{
          width: '102%',
          height: '10%',
        }}
      >
        <div className='row'>
          <div className='col-lg-5'>
            <div>
              <Typography.Text className='text-white'>
                Langage de programmation:
              </Typography.Text>
              <Select
                className='w-100'
                size='large'
                placeholder={`Sélectionnez une langue de programmation`}
                options={languageOptions}
                defaultValue={languageOptions[0]}
                onChange={(selectedOption) => onSelectChange(selectedOption)}
              />
            </div>
          </div>
          <div className='col-lg-5'>
            <div>
              <Typography.Text className='text-white'>Theme:</Typography.Text>
              <Select
                className='w-100'
                size='large'
                placeholder={`Sélectionnez un theme`}
                options={Object.entries(monacoThemes).map(
                  ([themeId, themeName]) => ({
                    label: themeName,
                    value: themeId,
                    key: themeId,
                  })
                )}
                value={theme}
                onChange={handleThemeChange}
              />
            </div>
          </div>
          <div className='col-lg-2'>
            <Button
              size='large'
              type='primary'
              className='mt-4'
              onClick={handleCompile}
            >
              Compiler
            </Button>
          </div>
        </div>
      </div> */}
      <div
        className='forced-left-container'
        style={{
          width: '102%',
          height: '90%',
        }}
      >
        <Editor
          height='100%'
          width={`100%`}
          value={value}
          theme={theme}
          language={language || 'javascript'}
          defaultValue=''
          onChange={handleEditorChange}
        />
      </div>
    </div>
  );
};

export default CodeEditor;
