import { Modal, Form, Input, Button, Typography, InputNumber } from "antd";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { updateTest } from "../../../Actions/test";
import FileBase64 from "react-file-base64";

const { Text } = Typography;
const { TextArea } = Input;

const TestModalTech = ({ id, test }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  form.setFieldsValue(test);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = (formValues) => {
    dispatch(updateTest(id, formValues));
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Text
        className='hoverText'
        type='success'
        strong
        id={id}
        onClick={showModal}
      >
        Editer
      </Text>
      <Modal
        title={`Editer le test '${test.title}' ?`}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={[
          <div className='d-flex justify-content-start'>
            <Button key='back' onClick={handleCancel}>
              Annuler
            </Button>
          </div>,
        ]}
      >
        <Form
          form={form}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 16 }}
          layout='horizontal'
          size='middle'
          onFinish={handleOk}
        >
          <Form.Item name='title' label='Titre'>
            <Input allowClear />
          </Form.Item>
          <Form.Item name='description' label='Description'>
            <TextArea
              placeholder='Description du test...'
              autoSize={{
                minRows: 3,
                maxRows: 5,
              }}
            />
          </Form.Item>
          <Form.Item name='duration' label='Durée'>
            <InputNumber type={"number"} />
          </Form.Item>
          <Form.Item name='image_file' label='Cahier de charge'>
            <FileBase64
              type='file'
              multiple={false}
              onDone={(e) => {
                form.setFieldsValue({
                  image_file: e.base64,
                });
              }}
            />
          </Form.Item>
          <Form.Item
            wrapperCol={{
              lg: { offset: 6 },
              md: { offset: 6 },
              sm: { offset: 6 },
              xs: { offset: 0 },
              span: 16,
            }}
          >
            <Button type='primary' htmlType='submit' className='mx-2'>
              Modifier
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default TestModalTech;
