import React, { useState, useEffect } from "react";
import { Card, Form, Select, Button, message } from "antd";
import { useSelector } from "react-redux";

import { inviteTest } from "../../../Api";
import { useNavigate } from "react-router-dom";
const { Option } = Select;

const TestInvitation = () => {
  const tests = useSelector((state) => state.tests);
  const [candidat, setCandidat] = useState({});
  const [form] = Form.useForm();
  const Navigate = useNavigate();

  const onSubmit = async (formValues) => {
    try {
      const { data } = await inviteTest(candidat._id, formValues.testTaken);
      if (data.message === "success") {
        message.success({
          content: `Test has been sent successfully`,
          style: {
            marginTop: "50px",
          },
        });
        Navigate("/administration/candidats/list");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setCandidat(JSON.parse(localStorage.getItem("candidat")));
  }, []);
  return (
    <div className="py-5">
      <Card title={`Envoyer un test à ${candidat.fullName}`}>
        <Form
          form={form}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 16 }}
          layout="horizontal"
          size="middle"
          onFinish={onSubmit}
        >
          <Form.Item
            label="Test disponible"
            name="testTaken"
            rules={[{ required: true, message: "Veuillez choisir un test" }]}
          >
            <Select>
              {tests.map((test) => {
                if (test.testType === "Théorique") {
                  return <Option value={test.testId}>{test.title}</Option>;
                } else {
                  return <Option value={test._id}>{test.title}</Option>;
                }
              })}
            </Select>
          </Form.Item>
          <Form.Item wrapperCol={{ span: 16, offset: 6 }}>
            <Button type="primary" block htmlType="submit">
              Envoyer
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default TestInvitation;
