import { Tag, notification } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ReactStopwatch from 'react-stopwatch';
import { EyeOutlined, ClockCircleOutlined } from '@ant-design/icons';
import { saveSurvey } from '../../Api';
import GlobalContext from '../../Context/GlobalContext';

const TestPage = () => {
  const { duration } = useContext(GlobalContext);

  const handleSaveSurvey = async () => {
    try {
      const { data } = await saveSurvey(id_candidat, id_test);
      console.log(data);
    } catch (error) {
      console.log(error);
    }
  };

  // document.querySelector("Root-sc-__sc-1esu8nk-2").;
  // setTimeout(() => {
  //   var typeformIframe = document.querySelector(
  //     '#tessst iframe[data-testid="iframe"]'
  //   );
  //   if (typeformIframe) {
  //     console.log("hhhhhhhhhhhh", typeformIframe);
  //     typeformIframe.style.pointerEvents = "none";

  //     // console.log("1", typeformIframe.contentWindow.document);
  //     // console.log("2", typeformIframe.contentDocument);
  //     // var iframeDoc =
  //     //   typeformIframe.contentDocument || typeformIframe.contentWindow.document;
  //     // var typeformBody = iframeDoc.body;

  //     // if (typeformBody) {
  //     //   typeformBody.setAttribute("onmousedown", "return false");
  //     //   typeformBody.setAttribute("onselectstart", "return false");
  //     // }
  //   }
  // }, [2000]);
  // setTimeout(() => {
  //   var typeformIframe = document.querySelector(
  //     '#tessst iframe[data-testid="iframe"]'
  //   );
  //   if (typeformIframe) {
  //     console.log('hhhhhhhhhhhh', typeformIframe);
  //     typeformIframe.addEventListener('load', function () {
  //       console.log('iframeDocument', typeformIframe.contentDocument);
  //       var iframeDocument = typeformIframe.contentDocument;
  //       var spans = iframeDocument.querySelectorAll('span');
  //       spans.forEach(function (span) {
  //         span.style.pointerEvents = 'none';
  //       });
  //     });
  //   }
  // }, [2000]);
  window.addEventListener('visibilitychange', async () => {
    if (document.visibilityState === 'hidden') {
      notification.open({
        type: 'warning',
        message:
          'Attention : Il est strictement interdit de quitter la page du test.',
        icon: <EyeOutlined />,
        style: {
          backgroundColor: '#fff2f0',
          border: '1px solid #db4654',
          borderRadius: '13px',
        },
        description:
          'Si vous quittez à nouveau la page, votre test sera automatiquement terminé.',
        onClick: () => {
          console.log('Notification Clicked!');
        },
        duration: 0,
      });

      await handleSaveSurvey();
    }
  });

  useEffect(() => {
    const script = document.createElement('script');
    script.src = '//embed.typeform.com/next/embed.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      // Cleanup the script tag on component unmount
      document.body.removeChild(script);
    };
  }, []);

  const { id_test, id_candidat } = useParams();

  return (
    <div style={{ width: '100vw', height: '100vh', overflow: 'hidden' }}>
      <ReactStopwatch
        seconds={0}
        minutes={0}
        hours={0}
        limit={`00:${duration}:00`}
        onChange={({ hours, minutes, seconds }) => {
          // do something
        }}
        onCallback={() =>
          notification.open({
            type: 'warning',
            style: {
              backgroundColor: '#fff2f0',
              border: '1px solid #db4654',
              borderRadius: '13px',
            },
            message: 'Délai dépassé. !',
            icon: <ClockCircleOutlined />,
            description: 'Vous avez dépassé la limite de temps pour le test.',
            onClick: () => {
              console.log('Notification Clicked!');
            },
            duration: 0,
          })
        }
        render={({ formatted, hours, minutes, seconds }) => {
          return (
            <Tag
              color='blue'
              style={{
                position: 'absolute',
                top: 10,
                right: 10,
                zIndex: '1000',
                fontSize: '25px',
                padding: '1rem',
                borderRadius: '13px',
              }}
            >
              {formatted}
            </Tag>
          );
        }}
      />
      <div
        id='tessst'
        data-tf-widget={id_test}
        data-tf-opacity='100'
        data-tf-iframe-props='title=PHP QCM'
        data-tf-transitive-search-params
        data-tf-medium='snippet'
        data-tf-hidden={`id_test=${id_test},id_candidat=${id_candidat}`}
        style={{ width: '100vw', height: '100vh', overflow: 'hidden' }}
      ></div>
    </div>
  );
};

export default TestPage;
