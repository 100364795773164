import { Button, Space, Table, Typography } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import axiosInstance from "../utils/axios";
import SessionDetails from "../Components/SessionDetails";
import EditSessionDrawer from "../Components/EditSessionDrawer";

const SessionsList = () => {
  const [sessions, setSessions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const { data } = await axiosInstance.get("/factory/sessions");

      setSessions(data.sessions);
      setLoading(false);
    }
    fetchData();
  }, [refresh]);

  const columns = [
    {
      title: "Session",
      render: (_, record) => (
        <Typography.Text strong>
          {dayjs(record.start_date).format("DD/MM/YYYY HH:mm:ss") +
            " - " +
            dayjs(record.end_date).format("DD/MM/YYYY HH:mm:ss")}
        </Typography.Text>
      ),
    },
    {
      title: "Action",
      render: (_, record) => (
        <Space>
          <SessionDetails
            name={
              dayjs(record.start_date).format("DD/MM/YYYY HH:mm:ss") +
              " - " +
              dayjs(record.end_date).format("DD/MM/YYYY HH:mm:ss")
            }
            payload={record}
          />
          <Button size="small" type="primary" danger>
            Supprimer
          </Button>
          <EditSessionDrawer
            name={
              dayjs(record.start_date).format("DD/MM/YYYY HH:mm:ss") +
              " - " +
              dayjs(record.end_date).format("DD/MM/YYYY HH:mm:ss")
            }
            payload={record}
            refresh={refresh}
            setRefresh={setRefresh}
          />
        </Space>
      ),
    },
  ];

  return (
    <div>
      <h4>Liste des sessions</h4>
      <Table loading={loading} dataSource={sessions} columns={columns} />
    </div>
  );
};

export default SessionsList;
