import React, { useState } from "react";
import { Button, Steps } from "antd";

const { Step } = Steps;

function StepsForm({ steps }) {
  const [current, setCurrent] = useState(0);

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  return (
    <>
      <Steps current={current}>
        {steps.map((item) => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>
      <div className="steps-content py-5">{steps[current].content}</div>
      <div className="steps-action d-flex justify-content-evenly">
        {current > 0 && (
          <Button
            style={{
              margin: "0 8px",
            }}
            onClick={() => prev()}
          >
            Précédent
          </Button>
        )}
        {current < steps.length - 1 && (
          <Button type="primary" onClick={() => next()}>
            Suivant
          </Button>
        )}
        {current === steps.length - 1 && (
          <Button type="primary" htmlType="submit" S>
            Créer mon compte !
          </Button>
        )}
      </div>
    </>
  );
}

export default StepsForm;
