import {
  FETCH_ALL,
  CREATE_TEST,
  DELETE_TEST,
  UPDATE_TEST,
} from "../Constants/actionsType";

const testReducer = (state = [], action) => {
  switch (action.type) {
    case FETCH_ALL:
      return action.payload;
    case CREATE_TEST:
      return [...state, action.payload];
    case "HIDE_ALL":
      return state.filter((test) => {
        test.isPublic = false;
        return test;
      });
    case "SHOW_ALL":
      return state.filter((test) => {
        test.isPublic = true;
        return test;
      });
    case DELETE_TEST:
      return state.filter((test) => test._id !== action.payload);
    case UPDATE_TEST:
      return state.map((test) =>
        test._id === action.payload._id ? action.payload : test
      );
    default:
      return state;
  }
};

export default testReducer;
