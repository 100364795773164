import React from "react";
import { Card, Typography, Form, Input, Button, message } from "antd";
import { ForgotPassword } from "../../Api";
import { useNavigate } from "react-router-dom";
import Logo from "../../Assets/logo_neopolis-dev.png";

const { Text, Title, Link } = Typography;

const ForgetPassword = () => {
  const [form] = Form.useForm();
  const Navigate = useNavigate();

  const onFinish = async (formValues) => {
    try {
      const { data } = await ForgotPassword(formValues);
      if (data.status === "success") {
        message.success({ content: "L'email a été envoyé!" });
      } else {
        message.error({ content: data.message });
      }
    } catch (error) {
      message.error({ content: error.response.data.message });
    }
  };
  return (
    <div className="row mx-0 justify-content-center pt-5">
      <div className="col-xl-5 col-lg-6 col-md-8 col-sm-12">
        <Card
          style={{ boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" }}
          title={
            <Title level={4} style={{ textAlign: "center" }}>
              Mot de passe oublié ?
            </Title>
          }
          actions={[
            <div className="d-flex justify-content-between mx-5">
              <img
                src={Logo}
                style={{ height: "35px" }}
                alt="Logo"
                onClick={() => {
                  Navigate("/");
                }}
              />
              <Text type="primary" className="mt-2" strong>
                ©2022 Neopolis-dev
              </Text>
            </div>,
          ]}
        >
          <Form
            form={form}
            labelCol={{
              span: 5,
            }}
            wrapperCol={{
              span: 15,
            }}
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            autoComplete="off"
          >
            <Form.Item
              wrapperCol={{
                lg: { offset: 5 },
                md: { offset: 5 },
                sm: { offset: 5 },
                xs: { offset: 0 },
                span: 13,
              }}
            >
              <p className="fw-light" style={{ letterSpacing: "1px" }}>
                Entrez l'email de votre compte et nous vous enverrons un lien
                pour réinitialiser votre mot de passe.
              </p>
            </Form.Item>
            <Form.Item
              name="email"
              label="Email"
              rules={[
                { required: true, message: "Veuillez saisir votre email" },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              wrapperCol={{
                lg: { offset: 5 },
                md: { offset: 5 },
                sm: { offset: 5 },
                xs: { offset: 0 },
                span: 15,
              }}
            >
              <div className="d-flex justify-content-between">
                <Button type="primary" htmlType="submit">
                  Envoyer!
                </Button>
                <Link
                  className="mt-2 fw-bold"
                  onClick={() => {
                    Navigate("/login");
                  }}
                >
                  Se connecter ?
                </Link>
              </div>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default ForgetPassword;
