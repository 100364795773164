import React, { useState } from "react";
import { Button, Checkbox, Form, Input, Card, Typography, Spin } from "antd";
import { loginAccount } from "../../Api";
import { useNavigate } from "react-router-dom";
import Logo from "../../Assets/logo_neopolis-dev.png";
const { Title, Text, Link } = Typography;

function Login() {
  const [form] = Form.useForm();
  const Navigate = useNavigate();
  const [loading, setloading] = useState(false);

  const [error, setError] = useState(null);

  const onFinish = async (formValues) => {
    try {
      setloading(true);
      const { data } = await loginAccount(formValues);

      if (data.Role === "ADMIN" || data.Role === "SUPER_ADMIN") {
        setloading(false);
        localStorage.setItem("token", data.token);
        localStorage.setItem("Role", data.Role);
        Navigate("/administration");
        window.location.reload();
      } else {
        setloading(false);
        localStorage.setItem("token", data.token);
        localStorage.setItem("Role", data.Role);
        localStorage.setItem("_id", data._id);
        localStorage.setItem("tests_taken", JSON.stringify(data.test_taken));
        Navigate("/");
      }
    } catch (error) {
      setloading(false);
      setError(error.response.data.message);
    }
  };

  return (
    <div className="row mx-0 justify-content-center pt-5 ">
      <div className="col-xl-5 col-lg-6 col-md-8 col-sm-12 ">
        <Card
          className="rounded-5 p-2"
          style={{ boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 7px 0px" }}
          title={
            <Title level={4} style={{ textAlign: "center" }}>
              Connectez-vous à votre compte
            </Title>
          }
          actions={[
            <div className="d-flex justify-content-between mx-5">
              <img
                src={Logo}
                style={{ height: "35px" }}
                alt="Logo"
                onClick={() => {
                  Navigate("/");
                }}
              />
              <Text type="primary" className="mt-2" strong>
                ©2022 Neopolis-dev
              </Text>
            </div>,
          ]}
        >
          {loading && (
            <div className="d-flex justify-content-center mb-3">
              <Spin size="large" />
            </div>
          )}
          <Form
            form={form}
            labelCol={{
              span: 5,
            }}
            wrapperCol={{
              span: 15,
            }}
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            autoComplete="off"
          >
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: "S’il vous plaît entrer votre email!",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Mot de passe"
              name="password"
              rules={[
                {
                  required: true,
                  message: "S’il vous plaît entrer votre mot de passe!",
                },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              name="remember"
              valuePropName="checked"
              wrapperCol={{
                lg: { offset: 5 },
                md: { offset: 5 },
                sm: { offset: 5 },
                xs: { offset: 0 },
                span: 13,
              }}
            >
              <Checkbox>se rappeler de moi</Checkbox>
            </Form.Item>

            <Form.Item
              wrapperCol={{
                lg: { offset: 5 },
                md: { offset: 5 },
                sm: { offset: 5 },
                xs: { offset: 0 },
                span: 15,
              }}
            >
              <div className="d-flex justify-content-between">
                <Button type="primary" htmlType="submit">
                  Se connecter
                </Button>
                <div className="d-flex justify-content-end">
                  <div className="d-flex flex-column">
                    <Link
                      onClick={() => {
                        Navigate("/forget-password");
                      }}
                    >
                      Mot de passe oublié ?
                    </Link>
                    <Link
                      onClick={() => {
                        Navigate("/getting-started");
                      }}
                    >
                      Pas de compte ?
                    </Link>
                  </div>
                </div>
              </div>
            </Form.Item>
            {error && (
              <Form.Item
                wrapperCol={{
                  lg: { offset: 5 },
                  md: { offset: 5 },
                  sm: { offset: 5 },
                  xs: { offset: 0 },
                  span: 13,
                }}
              >
                <Text type="danger" strong>
                  {error}
                </Text>
              </Form.Item>
            )}
          </Form>
        </Card>
      </div>
    </div>
  );
}

export default Login;
