import {
  AutoComplete,
  Button,
  Popconfirm,
  Typography,
  notification,
  DatePicker,
  Radio,
  message,
  Descriptions,
  Drawer,
  Tooltip,
} from "antd";
import { useEffect, useState } from "react";

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import axiosInstance from "../utils/axios";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
dayjs.extend(customParseFormat);
const { RangePicker } = DatePicker;
const range = (start, end) => {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
};

const languages = [
  {
    label: "Français",
    value: "french",
  },
  {
    label: "Anglais",
    value: "english",
  },
];

const levels = [
  {
    label: "Débutant",
    value: "Debutant",
  },
  {
    label: "Intermédiaires",
    value: "Intermediaries",
  },
  {
    label: "Avancé",
    value: "Avance",
  },
];

const EditSessionDrawer = ({ name, payload, refresh, setRefresh }) => {
  const [open, setOpen] = useState(false);
  const [language, setLanguage] = useState("");
  const [defaultDate, setDefaultDate] = useState([]);
  const [level, setLevel] = useState("");
  const [formatedDate, setFormatedDate] = useState([]);
  const [profiles, setProfiles] = useState([]);
  const [tempProfiles, setTempProfiles] = useState([]);
  const [singleSelectedProfile, setSingleSelectedProfile] = useState("");
  const [selectedProfiles, setSelectedProfiles] = useState([]);
  const [tests, setTests] = useState([]);
  const [tempTests, setTempTests] = useState([]);
  const [singleSelectedTest, setSingleSelectedTest] = useState("");
  const [selectedTests, setSelectedTests] = useState([]);
  const [api, contextHolder] = notification.useNotification();

  useEffect(() => {
    async function fetchProfiles() {
      const { data } = await axiosInstance.get("/auth/");
      setProfiles(data.accounts);
      const tempProfs = data.accounts.map((elem) => {
        return { value: elem.email };
      });
      setTempProfiles(tempProfs);
      return data;
    }

    async function fetchTests() {
      const { data } = await axiosInstance.get(
        `/tests/filter/by?language=${language}&level=${level}`
      );
      setTests(data.quizzes);
      return data;
    }

    fetchTests();

    fetchProfiles();
  }, []);

  useEffect(() => {
    async function fetchTests() {
      const { data } = await axiosInstance.get(
        `/tests/filter/by?language=${language}&level=${level}`
      );
      const temp = data?.quizzes?.map((elem) => {
        return { value: elem.title };
      });
      setTempTests(temp);
      return data;
    }

    if (language || level) {
      fetchTests();
    }
  }, [language, level]);

  useEffect(() => {
    if (tests.length > 0) {
      setLanguage(payload.session_language);
      setSelectedProfiles(payload.id_users);
      const tempQuizzes = payload.id_tests.map((elem) => {
        const temp = tests?.find((test) => test._id === elem);
        return temp?.title;
      });
      setSelectedTests(tempQuizzes);
      setDefaultDate([dayjs(payload.start_date), dayjs(payload.end_date)]);
      setFormatedDate([payload.start_date, payload.end_date]);
    }
  }, [tests]);

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const openNotificationWithIcon = (type, content, title) => {
    api[type]({
      message: title,
      description: content,
    });
  };

  const onAdd = () => {
    let tempSelectedProfiles = selectedProfiles;

    if (!tempSelectedProfiles.find((elem) => singleSelectedProfile === elem)) {
      tempSelectedProfiles.push(singleSelectedProfile);
      setSelectedProfiles(tempSelectedProfiles);
      setSingleSelectedProfile("");
    } else {
      return openNotificationWithIcon(
        "warning",
        "Le candidat sélectionné déjà existe dans la liste sélectionnée.",
        "Veuillez sélectionner un autre candidat"
      );
    }
  };

  const onAddTest = () => {
    let tempSelectedTests = selectedTests;
    tempSelectedTests.push(singleSelectedTest);
    setSelectedTests(tempSelectedTests);
    setSingleSelectedTest("");
  };

  const handleSave = async () => {
    if (selectedTests.length === 0) {
      return openNotificationWithIcon(
        "error",
        "Veuillez sélectionner au moins un test",
        "Impossible de créer la session!"
      );
    } else if (selectedProfiles.length === 0) {
      return openNotificationWithIcon(
        "error",
        "Veuillez sélectionner un profil de candidat valide",
        "Impossible de créer la session!"
      );
    } else if (formatedDate.length === 0) {
      return openNotificationWithIcon(
        "error",
        "Veuillez sélectionner une durée pour la session",
        "Impossible de créer la session!"
      );
    }

    let users = [];

    selectedProfiles.forEach((elem) => {
      if (profiles.find((element) => element.email === elem)) {
        users.push({
          email: elem,
          status: "Exists",
        });
      } else {
        users.push({
          email: elem,
          status: "Unknown",
        });
      }
    });

    let patch_payload = {
      id_users: users,
      id_tests: tests.filter(
        (elem) => selectedTests.includes(elem.title) && elem._id
      ),
      start_date: formatedDate[0],
      end_date: formatedDate[1],
      session_language: language,
    };

    try {
      const { data } = await axiosInstance.patch(
        "/session/" + payload._id,
        patch_payload
      );

      if (data)
        openNotificationWithIcon(
          "success",
          "",
          "La session a été modifiée avec succès"
        );

      setRefresh(!refresh);
      onClose();
    } catch (error) {
      message.error(error.response.data.message);
    }
  };

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current <= dayjs().subtract(1, "day").endOf("day");
  };

  const deleteSelectedCandidat = (idx) => {
    let temp = [];

    selectedProfiles.forEach((elem, index) => {
      if (idx !== index) {
        temp.push(elem);
      }
    });

    setSelectedProfiles(temp);
  };

  const deleteSelectedTest = (idx) => {
    let temp = [];

    selectedTests.forEach((elem, index) => {
      if (idx !== index) {
        temp.push(elem);
      }
    });

    setSelectedTests(temp);
  };

  return (
    <>
      <Button onClick={showDrawer} size="small">
        Modifier
      </Button>
      <Drawer
        title={"Session de: " + name}
        width={720}
        onClose={onClose}
        open={open}
        bodyStyle={{
          paddingBottom: 80,
        }}
        extra={
          <Button type="primary" onClick={handleSave}>
            Sauvegarder
          </Button>
        }
      >
        <Typography.Text>Durée de la session</Typography.Text>

        <RangePicker
          className="mb-4 w-100"
          size="large"
          defaultValue={defaultDate}
          onChange={(value, dateString) => {
            setFormatedDate(dateString);
          }}
          disabledDate={disabledDate}
          showTime={{
            hideDisabledOptions: true,
            defaultValue: [
              dayjs("00:00:00", "HH:mm:ss"),
              dayjs("11:59:59", "HH:mm:ss"),
            ],
          }}
          format="YYYY-MM-DD HH:mm:ss"
        />

        <Typography.Text>Langue</Typography.Text>
        <br />
        {contextHolder}
        <Radio.Group
          size="large"
          value={language}
          options={languages}
          onChange={({ target: { value } }) => {
            setLanguage(value);
          }}
          optionType="button"
          buttonStyle="solid"
        />

        <br />
        <br />
        <Typography.Text>Niveau</Typography.Text>
        <br />
        <Radio.Group
          size="large"
          options={levels}
          onChange={({ target: { value } }) => {
            setLevel(value);
          }}
          optionType="button"
          buttonStyle="solid"
        />
        <br />
        <br />

        <Typography.Text>Courriels des candidats</Typography.Text>
        <div className="d-flex">
          <AutoComplete
            size="large"
            options={tempProfiles}
            style={{
              width: "100%",
            }}
            value={singleSelectedProfile}
            onChange={(e) => setSingleSelectedProfile(e)}
            filterOption={(inputValue, option) =>
              option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !==
              -1
            }
          />
          <Button
            size="large"
            type="primary"
            className="d-flex justify-content-center align-items-center"
            style={{ marginLeft: "10px" }}
            onClick={onAdd}
          >
            <PlusOutlined />
          </Button>
        </div>
        <ul className="mx-4 mt-3">
          {selectedProfiles.map((elem, idx) => {
            return (
              <li className="d-flex align-items-center">
                <Typography.Text
                  type={
                    profiles.find((profile) => profile.email === elem)
                      ? "default"
                      : "danger"
                  }
                >
                  {elem}
                </Typography.Text>
                <Popconfirm
                  title="Êtes-vous sûr de supprimer le candidat sélectionné ?"
                  okText="Oui"
                  onConfirm={() => {
                    deleteSelectedCandidat(idx);
                  }}
                >
                  <DeleteOutlined
                    className="mx-2"
                    style={{ cursor: "pointer" }}
                  />
                </Popconfirm>
              </li>
            );
          })}
        </ul>
        <br />
        <br />
        <Typography.Text>Tests sélectionnés</Typography.Text>
        <div className="d-flex">
          <AutoComplete
            size="large"
            options={tempTests}
            style={{
              width: "100%",
            }}
            placeholder="Choisissez une langue ou un niveau ou les deux"
            value={singleSelectedTest}
            onChange={(e) => setSingleSelectedTest(e)}
            filterOption={(inputValue, option) =>
              option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !==
              -1
            }
          />
          <Button
            size="large"
            type="primary"
            className="d-flex justify-content-center align-items-center"
            style={{ marginLeft: "10px" }}
            onClick={onAddTest}
          >
            <PlusOutlined />
          </Button>
        </div>
        <ul className="mx-4 mt-3">
          {selectedTests?.map((elem, idx) => {
            return (
              <li className="d-flex align-items-center" key={idx}>
                <Typography.Text>{elem}</Typography.Text>{" "}
                <Popconfirm
                  title="Êtes-vous sûr de supprimer le test sélectionné ?"
                  okText="Oui"
                  onConfirm={() => {
                    deleteSelectedTest(idx);
                  }}
                >
                  <DeleteOutlined
                    className="mx-2"
                    style={{ cursor: "pointer" }}
                  />
                </Popconfirm>
              </li>
            );
          })}
        </ul>
      </Drawer>
    </>
  );
};

export default EditSessionDrawer;
