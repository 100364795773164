import {
  CREATE_MAIL_CANDIDAT,
  CREATE_CANDIDAT,
  FETCH_ALL,
  UPDATE_CANDIDAT,
  SUBMIT_RESULT,
  UPDATE_SINGLE_CANDIDAT,
  GET_ONE,
} from "../Constants/actionsType";
import moment from "moment";

const candidatReducer = (state = [], action) => {
  switch (action.type) {
    case CREATE_MAIL_CANDIDAT:
      return action.payload;
    case CREATE_CANDIDAT:
      return action.payload;
    case FETCH_ALL:
      return action.payload.map((candidat) => {
        candidat.submitted_at = moment(candidat.submitted_at).fromNow();
        return candidat;
      });
    case GET_ONE:
      return action.payload;
    case UPDATE_CANDIDAT:
      return state.map((candidat) =>
        candidat._id === action.payload._id ? action.payload : candidat
      );
    case UPDATE_SINGLE_CANDIDAT:
      return action.payload;
    case SUBMIT_RESULT:
      return action.payload;

    default:
      return state;
  }
};

export default candidatReducer;
