import React, { useEffect } from "react";
import { Input, Form, Button, Spin, message, Table, Space } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { createCategorie, fetchCategories } from "../../../Actions/categorie";
import CategorieModel from "../CategorieModel";

const GestionCategories = () => {
  const dispatch = useDispatch();
  const categories = useSelector((state) => state.categories);

  useEffect(() => {
    dispatch(fetchCategories());
  }, []);

  const [form] = Form.useForm();
  const onSubmit = (formValues) => {
    dispatch(createCategorie(formValues));
    message.success("Catégorie a étè ajouter avec success!");
  };

  const columns = [
    {
      title: "Catégorie",
      dataIndex: "categorie",
      key: "categorie",
    },
    {
      title: "Actions",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <CategorieModel categorie={record} />
        </Space>
      ),
    },
  ];

  return (
    <div className="container pt-5 mt-3">
      <div className="border-bottom mb-2">
        <h3>Gestion des Catégories</h3>
      </div>
      <div>
        <Form form={form} layout="horizontal" size="middle" onFinish={onSubmit}>
          <div className="d-flex flex-column flex-md-row flex-lg-row  ">
            <Form.Item name="categorie" label="Nouvelle Catégorie">
              <Input placeholder="Categorie" allowClear />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" className="w-100 ">
                Ajouter
              </Button>
            </Form.Item>
          </div>
        </Form>
        {!categories.length ? (
          <div
            style={{
              textAlign: "center",
              margin: "50px 0",
              marginBottom: "20px",
              padding: "30px 50px",
            }}
          >
            <Spin size="large" />
          </div>
        ) : (
          <div className="">
            <div className="col-lg-5 col-md-9 col-sm-12">
              <Table
                className="border-top pt-3"
                columns={columns}
                dataSource={categories}
                bordered={true}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default GestionCategories;
